import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { routeNames } from "routes";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { ICustomPlan, IUser } from "interfaces";
import { getCustomPlans, getUsers } from "services/users";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import AdminPlans from "pages/PurePlans";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Data {
  id: number;
  name: string;
  price: string;
  description: string;
  specifications: string;
  quarterDiscount: string;
  yearDiscount: string;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
    sorting: false,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    sorting: false,
  },
  {
    id: "specifications",
    numeric: false,
    disablePadding: false,
    label: "Specifications",
    sorting: false,
  },
  {
    id: "quarterDiscount",
    numeric: false,
    disablePadding: false,
    label: "Quaterly discount",
    sorting: false,
  },
  {
    id: "yearDiscount",
    numeric: false,
    disablePadding: false,
    label: "Yearly discount",
    sorting: false,
  },
];

const Plans = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();
  const navigate = useNavigate();
  const [plans, setPlans] = useState<IUser[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const { loading, setLoading } = useLoadingContext();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [value, setValue] = React.useState(0);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, planId: number) => {
    navigate("/plans/" + planId);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const plansToData = (plans: ICustomPlan[]) => {
    const data = plans.map((plan: ICustomPlan) => {
      return {
        id: plan.id ? plan.id : 0,
        name: plan.name,
        price: plan.price.toString() + " EUR/month",
        description: plan.description,
        specifications: plan.specs,
        quarterDiscount: plan.quarterDiscount.toString() + "%",
        yearDiscount: plan.yearDiscount.toString() + "%",
      };
    });

    return data;
  };

  useEffect(() => {
    setLoading(true);

    getCustomPlans(page + 1, rowsPerPage)
      .then((res) => {
        setPlans(res.data["hydra:member"]);
        setRows(plansToData(res.data["hydra:member"]));
        setOrdersCount(res.data["hydra:totalItems"]);
        setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [page, rowsPerPage]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Stack spacing={4}>
        <Stack direction="row">
          <Button
            variant="contained"
            onClick={() => navigate(routeNames.newPlans)}
          >
            {translation.plans.createPlan}
          </Button>
        </Stack>
        {rows.length > 0 ? (
          <>
            <Box sx={{ width: "100%", my: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {!loading ? (
                      rows.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            tabIndex={-1}
                            key={row.id}
                            style={{
                              height: 100,
                              cursor: "pointer",
                            }}
                          >
                            <TableCell component="th">
                              <Box>{row.name}</Box>
                            </TableCell>
                            <TableCell>{row.price}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell
                              dangerouslySetInnerHTML={{
                                __html: row.specifications,
                              }}
                            ></TableCell>
                            <TableCell>{row.quarterDiscount}</TableCell>
                            <TableCell>{row.yearDiscount}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow
                        style={{
                          height: 100 * rowsPerPage,
                        }}
                      >
                        <TableCell colSpan={6}>
                          <Stack alignItems="center">
                            <CircularProgress />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={ordersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={
                  loading
                    ? {
                        disabled: loading,
                      }
                    : undefined
                }
                nextIconButtonProps={
                  loading
                    ? {
                        disabled: loading,
                      }
                    : undefined
                }
              />
            </Box>
          </>
        ) : (
          !loading && (
            <Box sx={{ mt: 3 }}>
              <Typography>{translation.plans.noPlans}</Typography>
            </Box>
          )
        )}
      </Stack>
    </Box>
  );
};

export default Plans;
