import React from "react";
import { useField } from "formik";
import { Autocomplete, TextField } from "@mui/material";

const AutocompleteField = ({
  controlId,
  label,
  name,
  inputGroupPrepend,
  children,
  placeholder = true,
}: {
  controlId?: string;
  label?: string;
  name: string;
  inputGroupPrepend?: React.ReactNode;
  children: any;
  placeholder?: boolean;
}) => {
  const [value, setValue] = React.useState("");
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <>
      <Autocomplete
        id={field.name}
        onChange={(event: any, selected: any) => {
          if (selected) {
            helper.setValue(selected);
          } else {
            helper.setValue(null);
          }
        }}
        isOptionEqualToValue={(option, value) => {
          if (typeof value === "string") {
            return option.label === value;
          } else {
            return option == value;
          }
        }}
        // onInputChange={(event, selected: any) => {
        //   console.log(selected, typeof selected);
        //   if (selected.label) {
        //     setValue(selected);
        //   }
        //   //workaround for edge autocomplete infinite loop
        //   if (selected.label === "" && value.length === 1) {
        //     setValue("");
        //   }
        // }}
        getOptionLabel={(option: any) => option.label}
        onBlur={() => helper.setTouched(true)}
        value={field.value ? field.value : null}
        //inputValue={value}
        defaultValue={field.value ? field.value : null}
        options={children}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            error={isInvalid}
            helperText={isInvalid ? meta.error : ""}
            sx={{ minWidth: 259 }}
            {...params}
            label={label}
          />
        )}
      />
    </>
  );
};

export default AutocompleteField;
