import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import {
  IApplication,
  ICustomPlan,
  IDnsRecord,
  IDomain,
  IPlan,
  IUpdateApplication,
} from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import {
  getCustomPlansNoPagination,
  getPlansNoPagination,
  getUserById,
} from "services/users";
import {
  deleteDnsRecord,
  getApp,
  getAvailableDomains,
  installCertificateOnApp,
  newDnsRecord,
  updateApp,
  updateAppPrimaryDomain,
  updateDnsZone,
} from "services/apps";
import {
  Add,
  ContentCopyOutlined,
  DeleteOutline,
  Launch,
  Shield,
} from "@mui/icons-material";
import { errorMessage, formattedDate } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import InfoPopover from "components/InfoPopover";
import { LoadingButton } from "@mui/lab";
import { routeNames } from "routes";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import DatePickerField from "components/Form/DateTimePickerField";

const ApplicationPage = () => {
  const { pathname } = useLocation();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [app, setApp] = useState<IApplication | null>(null);
  const { applicationId } = useParams();
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [primaryDomain, setPrimaryDomain] = useState<IDnsRecord | null>(null);
  const [aliases, setAliases] = useState<IDnsRecord[]>([]);
  const [availableDomains, setAvailableDomains] = useState<IDomain[]>([]);
  const [plans, setPlans] = useState<any>([]);
  const [customPlans, setCustomPlans] = useState<any>([]);
  const [openNewDomain, setOpenNewDomain] = useState(false);
  const [openAddAlias, setOpenAddAlias] = useState(false);
  const [newAliasSubdomain, setNewAliasSubdomain] = useState("");
  const [newAliasDomain, setNewAliasDomain] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [aliasToPrimary, setAliasToPrimary] = useState<IDnsRecord | null>(null);
  const [aliasToDelete, setAliasToDelete] = useState<IDnsRecord | null>(null);
  const [addAliasError, setAddAliasError] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const navigate = useNavigate();

  const openPopOver = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getPlansNoPagination().then((res) => {
      setPlans(res.data["hydra:member"]);
    });
    getCustomPlansNoPagination().then((res) => {
      setCustomPlans(res.data["hydra:member"]);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (applicationId !== undefined) {
      getApp(applicationId)
        .then((response) => {
          if (response.data) {
            setApp(response.data);
            setDomains(response.data.domains);
            setNewAliasDomain(response.data.domains[0]["@id"]);
            setPrimaryDomain(response.data.primaryDomain);
            setAliases(response.data.aliases);
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [applicationId, updateTrigger]);

  const handleOpenNewDomain = () => {
    setOpenNewDomain(true);
    setRequestLoading(true);
    getAvailableDomains().then((res) => {
      setRequestLoading(false);
      setAvailableDomains(res.data["hydra:member"]);
    });
  };

  const handleCloseNewDomain = () => {
    setOpenNewDomain(false);
  };

  const handleCloseAddAlias = () => {
    setOpenAddAlias(false);
    setNewAliasSubdomain("");
  };

  const handleAddAlias = () => {
    if (newAliasSubdomain === "") {
      setAddAliasError("required");
    } else {
      if (app) {
        let alreadyExists = false;
        if (
          newAliasSubdomain === primaryDomain?.name &&
          newAliasDomain === primaryDomain.domain["@id"]
        ) {
          setAddAliasError("Record already exists");
          return;
        }
        for (let i = 0; i < aliases.length; i++) {
          if (
            newAliasSubdomain === aliases[0].name &&
            newAliasDomain === aliases[0].domain["@id"]
          ) {
            setAddAliasError("Record already exists");
            return;
          }
        }
        setRequestLoading(true);
        newDnsRecord({
          name: newAliasSubdomain,
          domain: newAliasDomain,
          type: "A",
          aliasApp: app["@id"],
        })
          .then((response) => {
            setRequestLoading(false);
            setSuccessMessage(translation.savedMessage);
            setUpdateTrigger(!updateTrigger);
            handleCloseAddAlias();
          })
          .catch((e) => {
            setRequestLoading(false);
            setErrorMessage(errorMessage(e));
            handleCloseAddAlias();
          });
      }
    }
  };

  const handleAddDomain = (domain: IDomain) => {
    if (app) {
      domain.app = app["@id"];
      setRequestLoading(true);
      updateDnsZone(domain)
        .then((res) => {
          setRequestLoading(false);
          setSuccessMessage(translation.savedMessage);
          setUpdateTrigger(!updateTrigger);
          handleCloseNewDomain();
        })
        .catch((e) => {
          setRequestLoading(false);
          setErrorMessage(errorMessage(e));
          handleCloseNewDomain();
        });
    }
  };

  const handleDeleteAlias = () => {
    if (aliasToDelete) {
      setRequestLoading(true);
      deleteDnsRecord(aliasToDelete)
        .then((res) => {
          setSuccessMessage(translation.deletedMessage);
          setUpdateTrigger(!updateTrigger);
          setOpenConfirmation(false);
          setRequestLoading(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setOpenConfirmation(false);
          setRequestLoading(false);
        });
    }
  };
  const handleMakePrimary = () => {
    if (aliasToPrimary && primaryDomain) {
      let newAliases = [...aliases];
      let index = newAliases.indexOf(aliasToPrimary);
      newAliases.splice(index, 1);
      newAliases.push(primaryDomain);
      let newApp: any = {
        id: app?.id,
        aliases: newAliases.map((alias) => alias["@id"]),
        primaryDomain: aliasToPrimary["@id"],
      };

      setRequestLoading(true);
      updateAppPrimaryDomain(newApp)
        .then((res) => {
          setSuccessMessage(translation.savedMessage);
          setUpdateTrigger(!updateTrigger);
          setOpenConfirmation(false);
          setRequestLoading(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setOpenConfirmation(false);
          setRequestLoading(false);
        });
    }
  };

  const handleInstallCertificate = () => {
    if (applicationId) {
      setRequestLoading(true);
      installCertificateOnApp(applicationId)
        .then((res) => {
          setSuccessMessage(translation.startedOperation);
          setUpdateTrigger(!updateTrigger);
          setOpenConfirmation(false);
          setRequestLoading(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setOpenConfirmation(false);
          setRequestLoading(false);
        });
    }
  };

  const initialValues: IUpdateApplication = {
    name: app ? app.name : "",
    invInterval: app ? app.invInterval : "m",
    plan: app?.plan["@id"] ? app.plan["@id"] : "",
    customPlan: app?.customPlan["@id"] ? app.customPlan["@id"] : "",
    endDate: app?.endDate ? app.endDate : "",
  };

  return (
    <Box>
      {isLoading || app == null ? (
        <LoadingIcon />
      ) : (
        <>
          <Stack spacing={4}>
            <Typography variant="h4">{app.name}</Typography>
            <Stack spacing={1}>
              <Typography variant="h5">
                {translation.applications.customer}
              </Typography>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography>
                  {app.customer.firstName +
                    " " +
                    app.customer.lastName +
                    " - " +
                    app.customer.companyInfo?.name}
                </Typography>
                <IconButton
                  size={"small"}
                  color="primary"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    const url = "/customers/" + app.customer.id;
                    window.open(url, "_blank");
                  }}
                >
                  <Launch fontSize="small" />
                </IconButton>
              </Stack>
              <Divider />
              <Stack
                direction={"row"}
                spacing={8}
                //justifyContent={"space-between"}
              >
                <Stack spacing={4}>
                  <Stack direction={"row"} spacing={8} flexWrap={"wrap"}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.status}:
                      </Typography>
                      <Typography>{app.status}</Typography>
                      {app.status !== "active" && (
                        <CircularProgress size={24} />
                      )}
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.ip}:
                      </Typography>
                      <Typography>{app.server.ip}</Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const url = app.server.ip;
                          navigator.clipboard.writeText(url);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>

                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"text.disabled"}>
                        {translation.applications.createdAt}:
                      </Typography>
                      <Typography color={"text.disabled"}>
                        {formattedDate(app.createdAt)}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography color={"primary.dark"}>
                      {translation.applications.url}:
                    </Typography>
                    <Typography>{app.primaryDomain?.fqdn}</Typography>
                    <IconButton
                      size={"small"}
                      color="primary"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        const url = "https://" + app.primaryDomain?.fqdn;
                        window.open(url, "_blank");
                      }}
                    >
                      <Launch fontSize="small" />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            {app.database.user && (
              <Stack direction="row" spacing={8} flexWrap={"wrap"}>
                {app.webCredentials.user && (
                  <Stack spacing={1}>
                    <Typography variant="h5">
                      {translation.applications.webCredentials}
                    </Typography>

                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.user}:
                      </Typography>
                      <Typography>{app.webCredentials.user}</Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const text = app.webCredentials.user;
                          navigator.clipboard.writeText(text);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.pass}:
                      </Typography>
                      <Typography sx={{ letterSpacing: 2 }}>
                        ••••••••
                      </Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const text = app.webCredentials.pass;
                          navigator.clipboard.writeText(text);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}
                {app.database.user && (
                  <Stack spacing={1}>
                    <Typography variant="h5">
                      {translation.applications.database}
                    </Typography>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.name}:
                      </Typography>
                      <Typography>{app.database.db}</Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const text = app.database.db;
                          navigator.clipboard.writeText(text);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.user}:
                      </Typography>
                      <Typography>{app.database.user}</Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const text = app.database.user;
                          navigator.clipboard.writeText(text);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.pass}:
                      </Typography>
                      <Typography sx={{ letterSpacing: 2 }}>
                        ••••••••
                      </Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const text = app.database.pass;
                          navigator.clipboard.writeText(text);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}
                {app.sftp.username && (
                  <Stack spacing={1}>
                    <Typography variant="h5">
                      {translation.applications.sftp}
                    </Typography>

                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.user}:
                      </Typography>
                      <Typography>{app.sftp.username}</Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const text = app.sftp.username;
                          navigator.clipboard.writeText(text);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography color={"primary.dark"}>
                        {translation.applications.pass}:
                      </Typography>
                      <Typography sx={{ letterSpacing: 2 }}>
                        ••••••••
                      </Typography>
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          const text = app.sftp.password;
                          navigator.clipboard.writeText(text);
                          setAnchorEl(event.currentTarget);
                          setTimeout(() => {
                            setAnchorEl(null);
                          }, 2000);
                        }}
                      >
                        <ContentCopyOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}
            {app.database.user && <Divider />}
            <Stack direction={"row"} spacing={8}>
              <Stack spacing={2}>
                <Typography variant="h5">
                  {translation.applications.domainManagement}
                </Typography>
                {domains.length > 0 &&
                  domains.map((domain) => {
                    return (
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                        key={"domainList-" + domain["@id"]}
                      >
                        <Typography>{domain.name}</Typography>
                        {/* <IconButton
                          size={"small"}
                          color="primary"
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            const url = app.server.ip;
                            navigator.clipboard.writeText(url);
                            setAnchorEl(event.currentTarget);
                            setTimeout(() => {
                              setAnchorEl(null);
                            }, 2000);
                          }}
                        >
                          <DeleteOutline fontSize="small" />
                        </IconButton> */}
                      </Stack>
                    );
                  })}
                <Box>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => handleOpenNewDomain()}
                  >
                    {translation.applications.addDomain}
                  </Button>
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h5">
                  {translation.applications.primaryDomain}
                </Typography>
                <Typography>{primaryDomain?.fqdn}</Typography>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h5">
                  {translation.applications.aliases}
                </Typography>
                {aliases.map((alias) => (
                  <Stack
                    direction={"row"}
                    key={"alias-" + alias["@id"]}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography>{alias.fqdn}</Typography>
                    <Stack direction={"row"} spacing={1}>
                      <Button
                        size={"small"}
                        onClick={() => {
                          setAliasToPrimary(alias);
                          setAliasToDelete(null);
                          setOpenConfirmation(true);
                        }}
                      >
                        {translation.applications.makePrimaryDomain}
                      </Button>
                      <IconButton
                        size={"small"}
                        color="warning"
                        onClick={() => {
                          setAliasToDelete(alias);
                          setAliasToPrimary(null);
                          setOpenConfirmation(true);
                        }}
                      >
                        <DeleteOutline></DeleteOutline>
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setOpenAddAlias(true)}
                >
                  {translation.applications.addAlias}
                </Button>
              </Stack>
            </Stack>
            <Box>
              <LoadingButton
                loading={requestLoading}
                variant="contained"
                startIcon={<Shield />}
                onClick={() => handleInstallCertificate()}
              >
                {translation.applications.installCertificate}
              </LoadingButton>
            </Box>
            <Divider />
            <Stack spacing={4}>
              <Typography variant="h5">
                {translation.applications.applicationSetings}
              </Typography>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values, actions) => {
                  actions.setSubmitting(true);
                  updateApp({
                    id: app.id,
                    name: values.name,
                    plan: values.plan,
                    customPlan: values.customPlan,
                    invInterval: values.invInterval,
                    endDate: values.endDate,
                  })
                    .then((res) => {
                      setSuccessMessage(translation.saveButton);
                      setUpdateTrigger(!updateTrigger);
                      actions.setSubmitting(true);
                    })
                    .catch((e) => {
                      setErrorMessage(errorMessage(e));
                      actions.setSubmitting(true);
                    });
                }}
              >
                {({ values, handleSubmit, isSubmitting }) => {
                  return (
                    <>
                      <form noValidate className="" onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                          <Box>
                            <FormTextField
                              label={translation.applications.nameLabel}
                              name="name"
                            />
                          </Box>
                          <Stack direction={"row"} spacing={3}>
                            <Box>
                              <SelectField
                                label={translation.applications.planLabel}
                                name="plan"
                              >
                                {plans.map((plan: IPlan) => {
                                  return (
                                    <MenuItem
                                      value={plan["@id"]}
                                      key={plan["@id"]}
                                    >
                                      {plan.name} - {plan.price} EUR
                                    </MenuItem>
                                  );
                                })}
                              </SelectField>
                            </Box>
                            <Box>
                              <SelectField
                                label={translation.applications.customPlanLabel}
                                name="customPlan"
                              >
                                {customPlans.map((customPlan: ICustomPlan) => {
                                  return (
                                    <MenuItem
                                      value={customPlan["@id"]}
                                      key={customPlan["@id"]}
                                    >
                                      {customPlan.name} - {customPlan.price} EUR
                                    </MenuItem>
                                  );
                                })}
                              </SelectField>
                            </Box>
                          </Stack>
                          <Stack direction={"row"} spacing={3}>
                            <Box>
                              <SelectField
                                label={
                                  translation.applications.invIntervalLabel
                                }
                                name="invInterval"
                              >
                                <MenuItem value="m">monthly</MenuItem>
                                <MenuItem value="q">quarterly</MenuItem>
                                <MenuItem value="y">yearly</MenuItem>
                              </SelectField>
                            </Box>
                            <Box>
                              <DatePickerField
                                name="endDate"
                                label={translation.applications.endDateLabel}
                              ></DatePickerField>
                            </Box>
                          </Stack>
                          <Box>
                            <LoadingButton
                              variant="contained"
                              loading={isSubmitting}
                              type="submit"
                            >
                              {translation.saveButton}
                            </LoadingButton>
                          </Box>
                        </Stack>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Stack>
          </Stack>
          <Dialog onClose={handleCloseNewDomain} open={openNewDomain} fullWidth>
            <DialogTitle>{translation.applications.addDomain}</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                {availableDomains.length > 0 && (
                  <List>
                    {availableDomains.map((domain) => {
                      return (
                        <ListItem
                          key={"domain-" + domain.id}
                          disablePadding
                          sx={{ width: "100%" }}
                        >
                          <ListItemButton
                            onClick={() => {
                              handleAddDomain(domain);
                            }}
                          >
                            <ListItemText primary={domain.name}></ListItemText>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
                {requestLoading && <CircularProgress />}
                {!requestLoading && availableDomains.length === 0 && (
                  <Stack spacing={2}>
                    <Typography>{translation.domains.noDomains}</Typography>
                    <Button
                      onClick={() => {
                        navigate(routeNames.domains);
                      }}
                    >
                      {translation.domains.addDomain}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleCloseNewDomain}>
                {translation.closeButton}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog onClose={handleCloseAddAlias} open={openAddAlias} fullWidth>
            <DialogTitle>{translation.applications.addAlias}</DialogTitle>
            <DialogContent>
              <Stack direction={"row"} sx={{ pt: 1 }} spacing={1}>
                <Box>
                  <TextField
                    label={translation.applications.subdomain}
                    error={!!addAliasError}
                    helperText={addAliasError}
                    onChange={(event) => {
                      setNewAliasSubdomain(event.target.value);
                    }}
                  ></TextField>
                </Box>
                <Box>
                  <Select
                    sx={{ minWidth: 250 }}
                    value={newAliasDomain}
                    onChange={(event: SelectChangeEvent) => {
                      setNewAliasDomain(event.target.value as string);
                    }}
                  >
                    {domains.map((domain) => (
                      <MenuItem
                        value={domain["@id"]}
                        key={"select-" + domain["@id"]}
                      >
                        {domain.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <InfoPopover infoContent="Use @ in the subdomain field if you don't want any."></InfoPopover>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleCloseAddAlias}>
                {translation.closeButton}
              </Button>
              <LoadingButton
                loading={requestLoading}
                variant="contained"
                onClick={handleAddAlias}
              >
                {translation.saveButton}
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog
            onClose={() => setOpenConfirmation(false)}
            open={openConfirmation}
            fullWidth
          >
            <DialogTitle>
              {translation.applications.actionConfirmation}
            </DialogTitle>
            <DialogContent>
              {aliasToPrimary && (
                <Stack spacing={2}>
                  <Typography>
                    {translation.applications.aliasToPrimaryConfirmation}
                  </Typography>
                  <Typography color={"primary.dark"}>
                    {aliasToPrimary.fqdn}
                  </Typography>
                </Stack>
              )}
              {aliasToDelete && (
                <Stack spacing={2}>
                  <Typography>
                    {translation.applications.aliasToDeleteConfirmation}
                  </Typography>
                  <Typography color={"primary.dark"}>
                    {aliasToDelete.fqdn}
                  </Typography>
                </Stack>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => setOpenConfirmation(false)}
              >
                {translation.closeButton}
              </Button>
              {aliasToPrimary && (
                <LoadingButton
                  loading={requestLoading}
                  variant="contained"
                  onClick={handleMakePrimary}
                >
                  {translation.applications.makePrimaryDomain}
                </LoadingButton>
              )}
              {aliasToDelete && (
                <LoadingButton
                  loading={requestLoading}
                  variant="contained"
                  onClick={handleDeleteAlias}
                  color="warning"
                >
                  {translation.deleteButton}
                </LoadingButton>
              )}
            </DialogActions>
          </Dialog>
          <Popover
            id="simple-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 1, color: "text.secondary" }}>
              {translation.copied}
            </Typography>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default ApplicationPage;
