import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, MenuItem, Stack, Paper, Button } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, generateRandomString } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import { createUser, updateUser } from "services/users";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import InfoPopover from "components/InfoPopover";
import { IUser, ICompanyInfo } from "interfaces";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import countrylist from "helpers/countryList";
import { setNestedObjectValues } from "formik";

const PartnerForm = ({ userToEdit }: { userToEdit?: IUser }) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const navigate = useNavigate();

  const translation = useTranslation();
  const { userId } = useUserContext();
  const partnerSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    lastName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    email: Yup.string()
      .email(translation.signUpEmailError)
      .required(translation.required),
    password: userToEdit
      ? Yup.string().matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.passwordRequirements
        )
      : Yup.string()
          .required(translation.required)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            translation.passwordRequirements
          ),
    companyInfo: Yup.object({
      name: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      legalName: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      vatId: Yup.string()
        .min(2, translation.signUpShortError)
        .max(31, translation.signUpLongError)
        .required(translation.required),
      regNo: Yup.string()
        .min(2, translation.signUpShortError)
        .max(31, translation.signUpLongError)
        .required(translation.required),
      otherInfo: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError),
      country: Yup.string().required(translation.required),
      county: Yup.string()
        .min(2, translation.signUpShortError)
        .max(63, translation.signUpLongError)
        .required(translation.required),
      city: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      zipCode: Yup.string()
        .min(2, translation.signUpShortError)
        .max(15, translation.signUpLongError),
      addressLine1: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError)
        .required(translation.required),
      addressLine2: Yup.string()
        .min(2, translation.signUpShortError)
        .max(255, translation.signUpLongError),
    }),
  });

  async function submitForm(
    values: IUser,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any,
    goBack = false
  ) {
    setSubmitting(true);
    if (!userToEdit) {
      try {
        await createUser({
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          roles: ["ROLE_PARTNER"],
          password: values.password,
          companyInfo: {
            name: values.companyInfo ? values.companyInfo.name : "",
            legalName: values.companyInfo ? values.companyInfo.legalName : "",
            vatId: values.companyInfo ? values.companyInfo.vatId : "",
            regNo: values.companyInfo ? values.companyInfo.regNo : "",
            otherInfo: values.companyInfo ? values.companyInfo.otherInfo : "",
            country: values.companyInfo ? values.companyInfo.country : "",
            county: values.companyInfo ? values.companyInfo.county : "",
            city: values.companyInfo ? values.companyInfo.city : "",
            zipCode: values.companyInfo ? values.companyInfo.zipCode : "",
            addressLine1: values.companyInfo
              ? values.companyInfo.addressLine1
              : "",
            addressLine2: values.companyInfo
              ? values.companyInfo.addressLine2
              : "",
          },
        });
        setSuccessMessage(translation.savedMessage);

        setSubmitting(false);
        if (goBack) {
          navigate(routeNames.partners);
        }
        resetForm();
      } catch (error) {
        setErrorMessage(errorMessage(error));
        setSubmitting(false);
      }
    } else {
      try {
        await updateUser({
          id: userToEdit.id,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          roles: ["ROLE_PARTNER"],
          newPassword: values.password ? values.password : undefined,
          companyInfo: {
            name: values.companyInfo ? values.companyInfo.name : "",
            legalName: values.companyInfo ? values.companyInfo.legalName : "",
            vatId: values.companyInfo ? values.companyInfo.vatId : "",
            regNo: values.companyInfo ? values.companyInfo.regNo : "",
            otherInfo: values.companyInfo ? values.companyInfo.otherInfo : "",
            country: values.companyInfo ? values.companyInfo.country : "",
            county: values.companyInfo ? values.companyInfo.county : "",
            city: values.companyInfo ? values.companyInfo.city : "",
            zipCode: values.companyInfo ? values.companyInfo.zipCode : "",
            addressLine1: values.companyInfo
              ? values.companyInfo.addressLine1
              : "",
            addressLine2: values.companyInfo
              ? values.companyInfo.addressLine2
              : "",
          },
        });
        setSuccessMessage(translation.savedMessage);

        setSubmitting(false);
        if (goBack) {
          navigate(routeNames.partners);
        }
      } catch (error) {
        setErrorMessage(errorMessage(error));
        setSubmitting(false);
      }
    }
  }

  const initialValues: IUser = {
    firstName: userToEdit ? userToEdit.firstName : "",
    lastName: userToEdit ? userToEdit.lastName : "",
    phone: userToEdit ? userToEdit.phone : "",
    email: userToEdit ? userToEdit.email : "",
    password: "",
    companyInfo: userToEdit
      ? {
          name: userToEdit.companyInfo?.name ? userToEdit.companyInfo.name : "",
          legalName: userToEdit.companyInfo?.legalName
            ? userToEdit.companyInfo.legalName
            : "",
          vatId: userToEdit.companyInfo?.vatId
            ? userToEdit.companyInfo.vatId
            : "",
          regNo: userToEdit.companyInfo?.regNo
            ? userToEdit.companyInfo.regNo
            : "",
          otherInfo: userToEdit.companyInfo?.otherInfo
            ? userToEdit.companyInfo.otherInfo
            : "",
          country: userToEdit.companyInfo?.country
            ? userToEdit.companyInfo.country
            : "",
          county: userToEdit.companyInfo?.county
            ? userToEdit.companyInfo.county
            : "",
          city: userToEdit.companyInfo?.city ? userToEdit.companyInfo.city : "",
          zipCode: userToEdit.companyInfo?.zipCode
            ? userToEdit.companyInfo.zipCode
            : "",
          addressLine1: userToEdit.companyInfo?.addressLine1
            ? userToEdit.companyInfo.addressLine1
            : "",
          addressLine2: userToEdit.companyInfo?.addressLine2
            ? userToEdit.companyInfo.addressLine2
            : "",
        }
      : {
          name: "",
          legalName: "",
          vatId: "",
          regNo: "",
          otherInfo: "",
          country: "",
          county: "",
          city: "",
          zipCode: "",
          addressLine1: "",
          addressLine2: "",
        },
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={partnerSchema}
        enableReinitialize
        onSubmit={() => {
          console.log("submited");
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setSubmitting,
          validateForm,
          setTouched,
          isValid,
          resetForm,
        }) => {
          return (
            <>
              <form noValidate className="" onSubmit={handleSubmit}>
                <Stack spacing={6}>
                  <Stack direction={"row"} spacing={3}>
                    <Box>
                      <FormTextField
                        label={translation.partners.firstNameLabel}
                        name="firstName"
                      />
                    </Box>
                    <Box>
                      <FormTextField
                        label={translation.partners.lastNameLabel}
                        name="lastName"
                      />
                    </Box>
                    <Box>
                      <FormTextField
                        label={translation.partners.emailLabel}
                        name="email"
                      />
                    </Box>
                    <Box>
                      <FormTextField
                        label={translation.partners.phoneLabel}
                        name="phone"
                      />
                    </Box>
                  </Stack>
                  <Stack direction={"row"} spacing={3}>
                    <Box>
                      <FormTextField
                        type="password"
                        label={translation.domains.passwordLabel}
                        name="password"
                      />
                    </Box>
                  </Stack>
                  <Stack spacing={3}>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <FormTextField
                          label={translation.partners.nameLabel}
                          name="companyInfo.name"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.partners.legalNameLabel}
                          name="companyInfo.legalName"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.partners.vatIdLabel}
                          name="companyInfo.vatId"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <FormTextField
                          label={translation.partners.regNolabel}
                          name="companyInfo.regNo"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.partners.otherInfoLabel}
                          name="companyInfo.otherInfo"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box sx={{ width: "250px" }}>
                        <AutocompleteCountryField
                          controlId="country1"
                          label={translation.partners.countryLabel}
                          name="companyInfo.country"
                          type="text"
                          options={countrylist}
                          placeholder={false}
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.partners.countyLabel}
                          name="companyInfo.county"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.partners.cityLabel}
                          name="companyInfo.city"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <FormTextField
                          label={translation.partners.addressLine1Label}
                          name="companyInfo.addressLine1"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.partners.addressLine2Label}
                          name="companyInfo.addressLine2"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.partners.zipCodeLabel}
                          name="companyInfo.zipCode"
                        />
                      </Box>
                    </Stack>
                  </Stack>

                  <Stack direction={"row"} spacing={2}>
                    <LoadingButton
                      variant="contained"
                      loading={isSubmitting}
                      onClick={async () => {
                        const validationErrors = await validateForm();
                        console.log(validationErrors);
                        if (Object.keys(validationErrors).length > 0) {
                          setTouched(
                            setNestedObjectValues(validationErrors, true)
                          );
                          return;
                        }
                        if (isValid) {
                          submitForm(values, setSubmitting, resetForm);
                        }
                      }}
                    >
                      {translation.saveButton}
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      loading={isSubmitting}
                      onClick={async () => {
                        const validationErrors = await validateForm();
                        if (Object.keys(validationErrors).length > 0) {
                          setTouched(
                            setNestedObjectValues(validationErrors, true)
                          );
                          return;
                        }
                        if (isValid) {
                          submitForm(values, setSubmitting, resetForm, true);
                        }
                      }}
                    >
                      {translation.saveAndBackButton}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>{" "}
    </>
  );
};

export default PartnerForm;
