import React from "react";
import { useField } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { NearMeTwoTone } from "@mui/icons-material";

const DatePickerField = ({ label, name }: { label?: string; name: string }) => {
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <>
      <DatePicker
        sx={{ width: 250 }}
        onChange={(newValue) => {
          helper.setValue(newValue?.toISOString());
        }}
        value={field.value ? dayjs(field.value) : null}
        label={label}
        defaultValue={meta.initialValue ? dayjs(meta.initialValue) : null}
        slotProps={{
          textField: {
            error: !!meta.error,
            helperText: meta.error,
          },
        }}
      />
    </>
  );
};

export default DatePickerField;
