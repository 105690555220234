import React, { useState, useContext } from "react";
import useFindUser from "components/customHooks/useFindUser";
import useCompany from "components/customHooks/useCompany";

const UserContext = React.createContext();
export const useUserContext = () => useContext(UserContext);

export default function UserContextProvider({ children }) {
  const { user, setUser, loginUser, refreshUser, isLoading, userId, role } =
    useFindUser();

  const {
    companyInfo,
    setCompanyInfo,
    companyIsLoading,
    setCompanyIsLoading,
    updateTrigger,
    setUpdateTrigger,
  } = useCompany();

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loginUser,
        refreshUser,
        isLoading,
        userId,
        role,
        companyInfo,
        setCompanyInfo,
        companyIsLoading,
        setCompanyIsLoading,
        updateTrigger,
        setUpdateTrigger,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
