import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, MenuItem, Stack, Paper, Button } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, generateRandomString } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import {
  createUser,
  getCustomPlansNoPagination,
  getPlansNoPagination,
  getUsersNoPagination,
  updateUser,
} from "services/users";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import InfoPopover from "components/InfoPopover";
import {
  IUser,
  INewApplication,
  IPlan,
  ICustomPlan,
  IDomain,
} from "interfaces";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import countrylist from "helpers/countryList";
import { setNestedObjectValues } from "formik";
import { useLoadingContext } from "components/contexts/LoadingContext";
import AutocompleteField from "components/Form/AutocompleteField";
import { createApp, getAvailableDomains } from "services/apps";

const ApplicationForm = ({ userToEdit }: { userToEdit?: IUser }) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const { loading, setLoading } = useLoadingContext();
  const [customers, setCustomers] = useState<any>([]);
  const [plans, setPlans] = useState<any>([]);
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [customPlans, setCustomPlans] = useState<any>([]);
  const navigate = useNavigate();

  const translation = useTranslation();
  const { userId } = useUserContext();
  const applicationSchema = Yup.object().shape({
    name: Yup.string().required(translation.required),
    customer: Yup.object().nullable().required(translation.required),
    invInterval: Yup.string().required(translation.required),
    plan: Yup.string().required(translation.required),
    customPlan: Yup.string().required(translation.required),
    domains: Yup.array().required(translation.required),
    type: Yup.string().required(translation.required),
  });

  const customersToData = (customers: IUser[]) => {
    const data = customers.map((customer: IUser) => {
      return {
        id: customer["@id"] ? customer["@id"] : 0,
        label: customer.companyInfo?.name,
      };
    });

    return data;
  };

  useEffect(() => {
    setLoading(true);
    if (customers !== null) {
      getUsersNoPagination().then((res) => {
        setCustomers(customersToData(res.data["hydra:member"]));
        setLoading(false);
      });
    }
    getPlansNoPagination().then((res) => {
      setPlans(res.data["hydra:member"]);
    });
    getCustomPlansNoPagination().then((res) => {
      setCustomPlans(res.data["hydra:member"]);
    });
    getAvailableDomains().then((res) => {
      setDomains(res.data["hydra:member"]);
    });
  }, []);

  async function submitForm(
    values: INewApplication,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any,
    goBack = false
  ) {
    setSubmitting(true);

    try {
      let response = await createApp({
        name: values.name,
        customer: values.customer.id,
        invInterval: values.invInterval,
        plan: values.plan,
        customPlan: values.customPlan,
        domains: values.domains,
        type: values.type,
        partner: "/users/" + userId,
      });

      setSuccessMessage(translation.savedMessage);

      setSubmitting(false);
      if (goBack) {
        navigate(routeNames.applications);
      } else {
        navigate("/apps/" + response.data.id);
      }
      resetForm();
    } catch (error) {
      setErrorMessage(errorMessage(error));
      setSubmitting(false);
    }
  }

  const initialValues: INewApplication = {
    name: "",
    customer: "",
    invInterval: "m",
    plan: "",
    customPlan: "",
    domains: [""],
    type: "wordpress",
    partner: "/users/" + userId,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={applicationSchema}
        enableReinitialize
        onSubmit={() => {
          console.log("submited");
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setSubmitting,
          validateForm,
          setTouched,
          isValid,

          resetForm,
        }) => {
          return (
            <>
              <form noValidate className="" onSubmit={handleSubmit}>
                <Stack spacing={6}>
                  <Stack direction={"row"} spacing={3}>
                    <Box>
                      <FormTextField
                        label={translation.applications.nameLabel}
                        name="name"
                      />
                    </Box>
                    <Box>
                      <AutocompleteField
                        label={translation.applications.customerLabel}
                        name="customer"
                        children={customers ? customers : []}
                      />
                    </Box>
                    <Box>
                      <SelectField
                        label={translation.applications.invIntervalLabel}
                        name="invInterval"
                      >
                        <MenuItem value="m">monthly</MenuItem>
                        <MenuItem value="q">quarterly</MenuItem>
                        <MenuItem value="y">yearly</MenuItem>
                      </SelectField>
                    </Box>
                  </Stack>
                  <Stack direction={"row"} spacing={3}>
                    <Box>
                      <SelectField
                        label={translation.applications.planLabel}
                        name="plan"
                      >
                        {plans.map((plan: IPlan) => {
                          return (
                            <MenuItem value={plan["@id"]} key={plan["@id"]}>
                              {plan.name} - {plan.price} EUR
                            </MenuItem>
                          );
                        })}
                      </SelectField>
                    </Box>
                    <Box>
                      <SelectField
                        label={translation.applications.customPlanLabel}
                        name="customPlan"
                      >
                        {customPlans.map((customPlan: ICustomPlan) => {
                          return (
                            <MenuItem
                              value={customPlan["@id"]}
                              key={customPlan["@id"]}
                            >
                              {customPlan.name} - {customPlan.price} EUR
                            </MenuItem>
                          );
                        })}
                      </SelectField>
                    </Box>
                  </Stack>
                  <Stack direction={"row"} spacing={3}>
                    <Box>
                      <SelectField
                        label={translation.applications.domainLabel}
                        name="domains[0]"
                      >
                        {domains.map((domain: IDomain) => {
                          return (
                            <MenuItem value={domain["@id"]} key={domain["@id"]}>
                              {domain.name}
                            </MenuItem>
                          );
                        })}
                      </SelectField>
                    </Box>
                    <Box>
                      <SelectField
                        label={translation.applications.typeLabel}
                        name="type"
                      >
                        <MenuItem value="wordpress">Wordpress</MenuItem>
                        <MenuItem value="wordpressmu">
                          Wordpress Multisite
                        </MenuItem>
                        <MenuItem value="woocommerce">Woocommerce</MenuItem>
                        <MenuItem value="phpstack">PHP</MenuItem>
                      </SelectField>
                    </Box>
                  </Stack>
                  <Stack direction={"row"} spacing={2}>
                    <LoadingButton
                      variant="contained"
                      loading={isSubmitting}
                      onClick={async () => {
                        const validationErrors = await validateForm();

                        if (Object.keys(validationErrors).length > 0) {
                          setTouched(
                            setNestedObjectValues(validationErrors, true)
                          );
                          return;
                        }

                        if (isValid) {
                          submitForm(values, setSubmitting, resetForm);
                        }
                      }}
                    >
                      {translation.saveButton}
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      loading={isSubmitting}
                      onClick={async () => {
                        const validationErrors = await validateForm();
                        if (Object.keys(validationErrors).length > 0) {
                          setTouched(
                            setNestedObjectValues(validationErrors, true)
                          );
                          return;
                        }
                        if (isValid) {
                          submitForm(values, setSubmitting, resetForm, true);
                        }
                      }}
                    >
                      {translation.saveAndBackButton}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>{" "}
    </>
  );
};

export default ApplicationForm;
