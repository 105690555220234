import * as React from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import PartnerForm from "./PartnerForm";

const NewPartner = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();

  return (
    <Box>
      <PartnerForm />
    </Box>
  );
};

export default NewPartner;
