import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserContext } from "components/contexts/UserContext";
//import Loading from "./../components/Loading";
import { routeNames } from "routes";
import { parseJwt } from "helpers";
import { object } from "yup";
import { login, logout } from "services/users";

export default function PrivateRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, isLoading } = useUserContext();
  const location = useLocation();
  if (isLoading) {
    //return <Loading />;
    return <React.Fragment>Loading...</React.Fragment>;
  }
  if (user) {
    const jwt = parseJwt(user.token);

    return <React.Fragment>{children}</React.Fragment>;
  }
  //redirect if there is no user

  return (
    <Navigate
      to={routeNames.home}
      replace
      state={{ path: location.pathname }}
    />
  );
}
