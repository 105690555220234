import { useState, useEffect } from "react";
import { parseJwt } from "helpers";
import { getUser, login, refreshLogin } from "services/users";

export default function useCompany() {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [companyIsLoading, setCompanyIsLoading] = useState(true);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  return {
    companyInfo,
    setCompanyInfo,
    companyIsLoading,
    setCompanyIsLoading,
    updateTrigger,
    setUpdateTrigger,
  };
}
