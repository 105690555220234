import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import { IServer, IUser } from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { getServerById, getUserById } from "services/users";
import ServerForm from "./ServerForm";

const EditServer = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [server, setServer] = useState<IServer | null>(null);
  const { serverId } = useParams();

  useEffect(() => {
    if (serverId !== undefined) {
      getServerById(serverId)
        .then((response) => {
          if (response.data) {
            setServer(response.data);
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [serverId]);

  return (
    <Box>
      {isLoading || server == null ? (
        <LoadingIcon />
      ) : (
        <>
          <ServerForm serverToEdit={server}></ServerForm>
        </>
      )}
    </Box>
  );
};

export default EditServer;
