import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { routeNames } from "routes";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { IApplication, IUser } from "interfaces";
import { getUsers } from "services/users";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { getApps } from "services/apps";
import { formattedDate } from "helpers";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import ExportToolbar from "components/ExportToolbar";

interface Data {
  id: number;
  name: string;
  plan: string;
  customer: string;
  endDate: string;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },
  {
    id: "plan",
    numeric: false,
    disablePadding: false,
    label: "Plan",
    sorting: false,
  },
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
    sorting: false,
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    sorting: false,
  },
];

const Applications = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();
  const navigate = useNavigate();
  const [apps, setApps] = useState<IApplication[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const { loading, setLoading } = useLoadingContext();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick: GridEventListener<"rowClick"> = (params) => {
    navigate("/apps/" + params.row.id);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "plan", headerName: "Plan", flex: 1 },
    { field: "customer", headerName: "Customer", flex: 1 },
    { field: "endDate", headerName: "End Date", flex: 1 },
  ];

  const appsToData = (apps: IApplication[]) => {
    const data = apps.map((app: IApplication) => {
      return {
        id: app.id ? app.id : 0,
        name: app.name,
        plan: app.customPlan.name,
        customer: app.customer.companyInfo ? app.customer.companyInfo.name : "",
        endDate: app.endDate ? formattedDate(app.endDate) : "",
      };
    });

    return data;
  };

  useEffect(() => {
    setLoading(true);

    getApps(page + 1, rowsPerPage)
      .then((res) => {
        setApps(res.data["hydra:member"]);
        setRows(appsToData(res.data["hydra:member"]));
        setOrdersCount(res.data["hydra:totalItems"]);
        setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [page, rowsPerPage]);

  return (
    <Box>
      <Stack direction="row">
        <Button
          variant="contained"
          onClick={() => navigate(routeNames.newApplications)}
        >
          {translation.applications.createAplicationButton}
        </Button>
      </Stack>
      {rows.length > 0 ? (
        <>
          <Box sx={{ width: "100%", my: 2 }}>
            <DataGrid
              rows={rows}
              onRowClick={handleClick}
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              columns={columns}
              slots={{ toolbar: ExportToolbar }}
            />
          </Box>
        </>
      ) : (
        !loading && (
          <Box sx={{ mt: 3 }}>
            <Typography>{translation.applications.noApplications}</Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default Applications;
