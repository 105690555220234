import { useField } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillTextField = ({ name }: { name: string }) => {
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <>
      <ReactQuill
        id={field.name}
        onChange={(value) => {
          helper.setValue(value);
        }}
        value={field.value}
        theme="snow"
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            ["link", "blockquote", "code"],
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
              { align: [] },
            ],
            ["image", "video"],
          ],
        }}
      />
    </>
  );
};

export default QuillTextField;
