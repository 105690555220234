import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import PartnerForm from "./PartnerForm";
import { IUser } from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { getUserById } from "services/users";

const EditPartner = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [partner, setPartner] = useState<IUser | null>(null);
  const { partnerId } = useParams();

  useEffect(() => {
    if (partnerId !== undefined) {
      getUserById(partnerId)
        .then((response) => {
          if (response.data) {
            setPartner(response.data);
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [partnerId]);

  return (
    <Box>
      {isLoading || partner == null ? (
        <LoadingIcon />
      ) : (
        <>
          <PartnerForm userToEdit={partner}></PartnerForm>
        </>
      )}
    </Box>
  );
};

export default EditPartner;
