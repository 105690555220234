import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  MenuItem,
  Stack,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, generateRandomString } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import {
  createPlan,
  createUser,
  deletePlan,
  updatePlan,
  updateUser,
} from "services/users";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import InfoPopover from "components/InfoPopover";
import { IUser, ICompanyInfo, IPlan } from "interfaces";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import countrylist from "helpers/countryList";
import QuillTextField from "components/Form/QuillTextField/QuillTextField";
import { setNestedObjectValues } from "formik";
import { useState } from "react";

const AdminPlanForm = ({ planToEdit }: { planToEdit?: IPlan }) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const [open, setOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const translation = useTranslation();
  const { userId } = useUserContext();
  const planSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    price: Yup.string().required(translation.required),
    specs: Yup.string().required(translation.required),
    description: Yup.string().required(translation.required),
    quarterDiscount: Yup.string().required(translation.required),
    yearDiscount: Yup.string().required(translation.required),
  });

  async function submitForm(
    values: IPlan,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any,
    isValid: boolean,
    validateForm: any,
    goBack = false
  ) {
    setSubmitting(true);

    if (isValid) {
      if (!planToEdit) {
        try {
          await createPlan({
            name: values.name,
            price: values.price,
            description: values.description,
            specs: values.specs,
            quarterDiscount: parseFloat(values.quarterDiscount.toString()),
            yearDiscount: parseFloat(values.yearDiscount.toString()),
          });
          setSuccessMessage(translation.savedMessage);

          setSubmitting(false);
          if (goBack) {
            navigate(routeNames.adminPlans);
          }
          resetForm();
        } catch (error) {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        }
      } else {
        try {
          await updatePlan({
            id: planToEdit.id,
            name: values.name,
            price: values.price,
            description: values.description,
            specs: values.specs,
            quarterDiscount: parseFloat(values.quarterDiscount.toString()),
            yearDiscount: parseFloat(values.yearDiscount.toString()),
          });
          setSuccessMessage(translation.savedMessage);

          setSubmitting(false);
          if (goBack) {
            navigate(routeNames.adminPlans);
          }
        } catch (error) {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        }
      }
    }
  }

  const handleDeletePlan = () => {
    if (planToEdit) {
      setDeleteLoading(true);
      deletePlan(planToEdit)
        .then((response) => {
          setDeleteLoading(false);
          setOpen(false);
          setSuccessMessage(translation.deletedMessage);
          navigate(routeNames.adminPlans);
        })
        .catch((e) => {
          setDeleteLoading(false);
          setOpen(false);
          setErrorMessage(errorMessage(e));
        });
    }
  };

  const initialValues: IPlan = {
    name: planToEdit ? planToEdit.name : "",
    price: planToEdit ? planToEdit.price : 0,
    specs: planToEdit ? planToEdit.specs : "",
    description: planToEdit ? planToEdit.description : "",
    quarterDiscount: planToEdit ? planToEdit.quarterDiscount : 0,
    yearDiscount: planToEdit ? planToEdit.yearDiscount : 0,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={planSchema}
        enableReinitialize
        validateOnMount
        onSubmit={() => {
          console.log("submited");
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validateForm,
          setTouched,
          isValid,

          setSubmitting,
          resetForm,
        }) => {
          return (
            <>
              <form noValidate className="" onSubmit={handleSubmit}>
                <Stack spacing={6}>
                  <Stack direction={"row"} spacing={3}>
                    <Box>
                      <FormTextField
                        label={translation.plans.name}
                        name="name"
                      />
                    </Box>
                    <Box>
                      <FormNumberField
                        label={translation.plans.price}
                        type="float"
                        name="price"
                      />
                    </Box>
                  </Stack>
                  <Stack spacing={3}>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <Typography sx={{ mb: 1 }}>
                          {translation.plans.description}
                        </Typography>
                        <QuillTextField name="description" />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <Typography sx={{ mb: 1 }}>
                          {translation.plans.specifications}
                        </Typography>
                        <QuillTextField name="specs" />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <NumberPercentageField
                          label={translation.plans.qDiscount}
                          name="quarterDiscount"
                        />
                      </Box>
                      <Box>
                        <NumberPercentageField
                          label={translation.plans.yDiscount}
                          name="yearDiscount"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"} spacing={2}>
                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (Object.keys(validationErrors).length > 0) {
                            setTouched(
                              setNestedObjectValues(validationErrors, true)
                            );
                            return;
                          }
                          if (isValid) {
                            submitForm(
                              values,
                              setSubmitting,
                              resetForm,
                              isValid,
                              validateForm
                            );
                          }
                        }}
                      >
                        {translation.saveButton}
                      </LoadingButton>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        loading={isSubmitting}
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (Object.keys(validationErrors).length > 0) {
                            setTouched(
                              setNestedObjectValues(validationErrors, true)
                            );
                            return;
                          }
                          if (isValid) {
                            submitForm(
                              values,
                              setSubmitting,
                              resetForm,
                              isValid,
                              validateForm,
                              true
                            );
                          }
                        }}
                      >
                        {translation.saveAndBackButton}
                      </LoadingButton>
                    </Stack>
                    {planToEdit && (
                      <Box>
                        <Button
                          variant="outlined"
                          color={"error"}
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          {translation.deleteButton}
                        </Button>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>{" "}
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>{translation.applications.actionConfirmation}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              {translation.plans.planToDeleteConfirmation}
            </Typography>
            <Typography color={"primary.dark"}>{planToEdit?.name}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            {translation.closeButton}
          </Button>

          {planToEdit && (
            <LoadingButton
              loading={deleteLoading}
              variant="contained"
              onClick={handleDeletePlan}
              color="warning"
            >
              {translation.deleteButton}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminPlanForm;
