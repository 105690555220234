import React from "react";
import Navbar from "./Navbar";
import SideNav from "./SideNav";
import { Box } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Backdrop,
  Divider,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import { useLoadingContext } from "components/contexts/LoadingContext";
import LoadingIcon from "components/Feedback/LoadingIcon";
import SnackbarError from "components/SnackbarError";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import SnackbarSuccess from "components/SnackbarSuccess";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/ro";
import useTranslation from "components/customHooks/translations";

const Layout = ({
  titleVariable,
  children,
}: {
  titleVariable: string;
  children: React.ReactNode;
}) => {
  const translation = useTranslation();
  const { companyIsLoading, isLoading, userId } = useUserContext();
  const { loading } = useLoadingContext();
  const { errorMessage, successMessage } = useSnackbarContext();
  const [locale, _setLocale] = React.useState("ro");

  const theme = useTheme();

  const title = translation[
    titleVariable as keyof typeof translation
  ] as string;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {userId && (
        <>
          <Navbar />
          <SideNav />
        </>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
        >
          <Toolbar />
          <Stack sx={{ pt: 6, pb: 3 }}>
            <Typography variant="h6" color="primary.light">
              {title}
            </Typography>
            <Divider
              sx={{ borderColor: theme.palette.primary.light }}
            ></Divider>
          </Stack>
          <Paper sx={{ p: 3 }}>{children}</Paper>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading || loading || companyIsLoading}
          >
            <LoadingIcon></LoadingIcon>
          </Backdrop>
          <SnackbarError errorMessage={errorMessage} />
          <SnackbarSuccess successMessage={successMessage} />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default Layout;
