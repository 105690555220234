import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import { ICustomPlan, IUser } from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { getCustomPlanById, getUserById } from "services/users";
import CustomPlanForm from "./CustomPlanForm";

const EditPlan = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState<ICustomPlan | null>(null);
  const { planId } = useParams();

  useEffect(() => {
    if (planId !== undefined) {
      getCustomPlanById(planId)
        .then((response) => {
          if (response.data) {
            setPlan(response.data);
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [planId]);

  return (
    <Box>
      {isLoading || plan == null ? (
        <LoadingIcon />
      ) : (
        <>
          <CustomPlanForm planToEdit={plan}></CustomPlanForm>
        </>
      )}
    </Box>
  );
};

export default EditPlan;
