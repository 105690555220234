import { Field } from "formik";
import TextField from "@mui/material/TextField";

const FormTextField = ({
  label,
  name,
  type = "text",
  multiline = false,
  minRows = 1,
}: {
  label?: string;
  name: string;
  type: string;
  multiline?: boolean;
  minRows?: number;
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <TextField
            {...field}
            minRows={minRows}
            multiline={multiline}
            sx={{ bgcolor: "white" }}
            label={label}
            type={type}
            value={field.value}
            error={isInvalid}
            helperText={isInvalid ? form.errors[field.name] : ""}
          />
        );
      }}
    </Field>
  );
};

FormTextField.defaultProps = {
  type: "text",
  inputGroupPrepend: null,
};

export default FormTextField;
