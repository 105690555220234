import * as React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        max={100}
        min={0}
        isAllowed={(values) => {
          const { floatValue } = values;
          if (floatValue) return floatValue <= 100;
          else return true;
        }}
        allowNegative={false}
        decimalScale={2}
        valueIsNumericString
        suffix="%"
      />
    );
  }
);

const NumberPercentageField = ({
  label,
  name,
}: {
  label?: string;
  name: string;
}) => {
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <TextField
      id="standard-basic"
      label={label}
      value={field.value === 0 ? "" : field.value}
      onChange={(e) => {
        helper.setValue(e.target.value);
      }}
      sx={{ width: 250 }}
      placeholder="%"
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        error: isInvalid,
      }}
      error={isInvalid}
      helperText={isInvalid ? meta.error : ""}
    />
  );
};

export default NumberPercentageField;
