import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import { Avatar, Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { updateUser, getUserById } from "services/users";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import { AddAPhotoOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";

const MyProfile = () => {
  const { userId, updateTrigger, setUpdateTrigger } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [userData, setUserData] = useState<any>(null);
  const [userIsLoading, setUserIsLoading] = useState(true);
  const [formError, setFormError] = React.useState("");
  const translation = useTranslation();
  const profileSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    lastName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    email: Yup.string()
      .email(translation.signUpEmailError)
      .required(translation.required),
  });

  useEffect(() => {
    getUserById(userId)
      .then((user) => {
        if (user.data) {
          setUserData(user.data);

          setUserIsLoading(false);
        }
      })
      .catch((e) => console.log(e));
  }, [updateTrigger]);

  return (
    <>
      {userIsLoading ? (
        <LoadingIcon></LoadingIcon>
      ) : (
        userData && (
          <Box>
            <PageHeader>{translation.myProfile}</PageHeader>
            <Grid2 container>
              <Grid2 lg={6}>
                {" "}
                <Paper sx={{ padding: 2 }}>
                  <Formik
                    initialValues={{
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                      email: userData.email,
                      photo: userData.photo,
                      nickname: userData.nickname ? userData.nickname : "",
                    }}
                    validationSchema={profileSchema}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                      // const responsePromise = updateUser(
                      //   {
                      //     ...values,
                      //   },
                      //   userData.id
                      // );
                      // responsePromise
                      //   .then((res) => {
                      //     setSuccessMessage(translation.savedMessage);
                      //     setSubmitting(false);
                      //     setUpdateTrigger(!updateTrigger);
                      //   })
                      //   .catch((error) => {
                      //     setErrorMessage(errorMessage(error));
                      //     setSubmitting(false);
                      //   });
                    }}
                  >
                    {({
                      handleSubmit,
                      errors,
                      touched,
                      isValid,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form noValidate className="" onSubmit={handleSubmit}>
                        <h5 className="error-label">{formError}</h5>

                        <Box display="flex" alignItems="flex-end" mb={3}>
                          <Avatar
                            variant="rounded"
                            src={
                              values.photo
                                ? values.photo.contentUrl
                                : "/assets/images/faces/user.png"
                            }
                            sx={{
                              height: 64,
                              width: 64,
                            }}
                          />

                          <Box ml={-2.5} mb={-1.5}>
                            <label htmlFor="profile-image">
                              <Button
                                component="span"
                                color="primary"
                                variant={"contained"}
                                sx={{
                                  height: "auto",
                                  p: "8px",
                                  borderRadius: "50%",
                                  minWidth: "32px",
                                }}
                              >
                                <AddAPhotoOutlined fontSize="small" />
                              </Button>
                            </label>
                          </Box>
                          <Box display="none">
                            <input
                              onChange={(e) => {
                                const reader = new FileReader();
                                if (e.target.files !== null) {
                                  reader.readAsDataURL(e.target.files[0]);
                                  reader.onload = function () {
                                    setFieldValue("photo", {
                                      contentUrl: reader.result,
                                    });
                                  };
                                }
                              }}
                              id="profile-image"
                              accept="image/*"
                              type="file"
                            />
                          </Box>
                        </Box>

                        <Stack spacing={2}>
                          <FormTextField
                            label={translation.myProfilePage.email}
                            name="email"
                          />

                          <FormTextField
                            label={translation.myProfilePage.firstName}
                            name="firstName"
                          />

                          <FormTextField
                            label={translation.myProfilePage.lastName}
                            name="lastName"
                          />
                          <FormTextField
                            label={translation.myProfilePage.nickname}
                            name="nickname"
                          />
                        </Stack>

                        <Box display="flex" justifyContent="flex-end" my={2}>
                          <LoadingButton
                            type="submit"
                            loading={isSubmitting}
                            variant={"contained"}
                          >
                            {translation.saveButton}
                          </LoadingButton>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Paper>
              </Grid2>
            </Grid2>
          </Box>
        )
      )}
    </>
  );
};

export default MyProfile;
