import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Layout from "components/Layout";
import CleanLayout from "components/CleanLayout";
import PageNotFound from "pages/PageNotFound";
import routes from "routes";
import { specialRoutes } from "routes";
import PrivateRoute from "components/PrivateRoute";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RoundaboutLeft } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#297DC6",
      dark: "#004B8D",
      light: "#42A5F5",
    },
    warning: {
      main: "#ED6C02",
      dark: "#E65100",
      light: "#FF9800",
    },
    background: {
      default: "#F8FAFD",
      paper: "#FFFFFF",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.60)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    fontFamily: [
      "Lexend Deca",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: 55,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    h2: {
      fontSize: 44,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    h3: {
      fontSize: 35,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    h4: {
      fontSize: 28,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    h5: {
      fontSize: 22,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    h6: {
      fontSize: 16,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    body1: {
      fontSize: 18,
      lineHeight: "150%",
    },
    body2: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "143%",
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: "175%",
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: "157%",
      fontWeight: 500,
    },
    overline: {
      fontSize: 14,
      lineHeight: "200%",
    },
    caption: {
      fontSize: 12,
      lineHeight: "166%",
    },
    button: {
      fontSize: 16,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 300,
          fontSize: 20,
          lineHeight: "30px",
        },
        body: {
          fontWeight: 500,
          fontSize: 16,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: "30px",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: "30px",
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: "0 8px",
    //     },
    //   },
    // },
    MuiAlert: {
      styleOverrides: {
        icon: {
          width: "1.4em",
          height: "1.4em",
        },
        message: {
          fontSize: 22,
        },
      },
    },
    // MuiAvatar: {
    //   styleOverrides: {
    //     rounded: {
    //       borderRadius: "0 8px",
    //     },
    //   },
    // },
    // MuiPaper: {
    //   styleOverrides: {
    //     rounded: {
    //       borderRadius: "0 8px",
    //     },
    //   },
    // },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {routes.map((route: any, index: number) => (
            <Route
              path={route.path}
              key={"route-key-" + index}
              element={
                route.authenticatedRoute ? (
                  <Layout titleVariable={route.titleVariable}>
                    <PrivateRoute>
                      <route.component />
                    </PrivateRoute>
                  </Layout>
                ) : (
                  <Layout titleVariable={route.titleVariable}>
                    <route.component />
                  </Layout>
                )
              }
            />
          ))}

          {specialRoutes.map((route: any, index: number) => (
            <Route
              path={route.path}
              key={"sRoute-key-" + index}
              element={
                <CleanLayout>
                  <route.component />
                </CleanLayout>
              }
            />
          ))}
          <Route
            path="*"
            element={
              <Layout titleVariable={"notFound"}>
                <PageNotFound />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
