import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Popover,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import { IDnsRecord, IDomain, IEmail } from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { getUserById } from "services/users";
import { deleteEmail, getDomain, setUpEmailDomain } from "services/apps";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import InfoPopover from "components/InfoPopover";
import {
  Add,
  ContentCopyOutlined,
  DeleteOutline,
  Launch,
} from "@mui/icons-material";
import RecordForm from "./RecordForm";
import { recordTTLs } from "helpers/dnsRecordsData";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import EmailForm from "./EmailForm";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Data {
  id: string;
  type: string;
  name: string;
  value: string;
  ttl: string;
  app?: string;
}

const headCells: HeadCell[] = [
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    sorting: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },
  {
    id: "value",
    numeric: false,
    disablePadding: false,
    label: "Value",
    sorting: false,
  },
  {
    id: "ttl",
    numeric: false,
    disablePadding: false,
    label: "TTL",
    sorting: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    sorting: false,
  },
];

const DomainPage = () => {
  const { pathname } = useLocation();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);
  const [domain, setDomain] = useState<IDomain | null>(null);
  const [rows, setRows] = useState<Data[]>([]);
  const { domainId } = useParams();
  const [records, setRecords] = useState<IDnsRecord[]>([]);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [showNewRecord, setShowNewRecord] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState<IDnsRecord | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [value, setValue] = React.useState(0);
  const [showNewEmail, setShowNewEmail] = useState(false);
  const [emailToEdit, setEmailToEdit] = useState<IEmail | null>(null);
  const [emailToDelete, setEmailToDelete] = useState<IEmail | null>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopOver = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const recordsToData = (records: IDnsRecord[]) => {
    records.sort((a, b) => {
      if (a.id && b.id) {
        return a.id - b.id;
      }
      return 0;
    });
    const data = records.map((record: IDnsRecord) => {
      return {
        id: record["@id"] ? record["@id"] : "",
        type: record.type ? record.type : "",
        name: record.name ? record.name : "",
        value: record.value ? record.value : "",
        ttl: record.ttl
          ? recordTTLs[record.ttl as keyof typeof recordTTLs]
          : "",
        app: record.app ? record.app : record.aliasApp,
      };
    });

    return data;
  };

  useEffect(() => {
    if (domainId !== undefined) {
      setIsLoading(true);
      getDomain(domainId)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            setDomain(response.data);
            setRecords(response.data.records);
            setRows(recordsToData(response.data.records));
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [domainId, updateTrigger]);

  const handleSetUpEmail = () => {
    if (domainId !== undefined) {
      setRequestLoading(true);
      setUpEmailDomain(domainId)
        .then((res) => {
          if (res.message) {
            setErrorMessage(errorMessage(res));
            setRequestLoading(false);
          } else {
            setSuccessMessage(translation.savedMessage);
            setUpdateTrigger(!updateTrigger);
            setRequestLoading(false);
          }
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setRequestLoading(false);
        });
    }
  };

  const handleDeleteEmail = () => {
    if (emailToDelete) {
      setRequestLoading(true);
      deleteEmail(emailToDelete)
        .then((res) => {
          setSuccessMessage(translation.deletedMessage);
          setUpdateTrigger(!updateTrigger);
          setOpenConfirmation(false);
          setRequestLoading(false);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setOpenConfirmation(false);
          setRequestLoading(false);
        });
    }
  };

  return (
    <Box>
      {isLoading || domain == null ? (
        <LoadingIcon />
      ) : (
        <>
          <Stack spacing={2}>
            <Typography variant="h4">{domain.name}</Typography>
            <Stack direction={"row"} spacing={1}>
              <Typography color={"primary.dark"}>
                {translation.domains.status}:
              </Typography>
              <Typography>{domain.cloudflareStatus}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <Typography color={"primary.dark"}>
                {translation.domains.nameServers}:
              </Typography>
              <Typography>{domain.nameservers?.join(" | ")}</Typography>
            </Stack>
          </Stack>
          <Stack spacing={4} sx={{ mt: 8 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="domain tabs"
            >
              <Tab label={translation.domains.records} {...a11yProps(0)} />
              <Tab label={translation.domains.email} {...a11yProps(1)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>
              <Box sx={{ mb: 4 }}>
                <Button
                  variant="contained"
                  startIcon={<Add></Add>}
                  onClick={() => {
                    setShowNewRecord(true);
                  }}
                >
                  {translation.domains.addRecord}
                </Button>
              </Box>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    numSelected={1}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {!isLoading ? (
                      rows.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row.id}
                            style={{
                              height: 100,
                            }}
                          >
                            <TableCell component="th">
                              <Box>{row.type}</Box>
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Box sx={{ width: 500, wordWrap: "break-word" }}>
                                {row.value}
                              </Box>
                            </TableCell>
                            <TableCell>{row.ttl}</TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1}>
                                <Button
                                  variant="contained"
                                  disabled={!!row.app}
                                  onClick={() => {
                                    setShowNewRecord(true);
                                    setRecordToEdit(records[index]);
                                  }}
                                >
                                  {translation.editButton}
                                </Button>
                                {!!row.app && (
                                  <InfoPopover
                                    infoContent={translation.domains.appManaged}
                                  />
                                )}
                                {!!row.app && (
                                  <IconButton
                                    size={"small"}
                                    color="primary"
                                    onClick={(
                                      event: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                      const url = row.app;
                                      window.open(url, "_blank");
                                    }}
                                  >
                                    <Launch fontSize="small" />
                                  </IconButton>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow
                        style={{
                          height: 100,
                        }}
                      >
                        <TableCell colSpan={6}>
                          <Stack alignItems="center">
                            <CircularProgress />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {!domain.hasEmail && (
                <LoadingButton
                  variant="contained"
                  loading={requestLoading}
                  onClick={handleSetUpEmail}
                >
                  {translation.domains.setEmailButton}
                </LoadingButton>
              )}
              {domain.hasEmail && (
                <Stack spacing={2}>
                  <Box sx={{ mb: 4 }}>
                    <Button
                      variant="contained"
                      startIcon={<Add></Add>}
                      onClick={() => {
                        setShowNewEmail(true);
                      }}
                    >
                      {translation.domains.addEmail}
                    </Button>
                  </Box>
                  <Typography variant="h5">
                    {translation.domains.emailAdresses}
                  </Typography>
                  {domain.emailAddresses?.map((emailAddress) => (
                    <Stack spacing={1}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Stack spacing={1}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Typography color={"primary.dark"}>
                              {translation.domains.emailLabel}:
                            </Typography>
                            <Typography>{emailAddress.fullEmail}</Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Typography color={"primary.dark"}>
                              {translation.domains.passwordLabel}:
                            </Typography>
                            <Typography sx={{ letterSpacing: 2 }}>
                              ••••••••
                            </Typography>
                            <IconButton
                              size={"small"}
                              color="primary"
                              onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                const text = emailAddress.password;
                                navigator.clipboard.writeText(text);
                                setAnchorEl(event.currentTarget);
                                setTimeout(() => {
                                  setAnchorEl(null);
                                }, 2000);
                              }}
                            >
                              <ContentCopyOutlined fontSize="small" />
                            </IconButton>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Button
                            size={"small"}
                            onClick={() => {
                              setEmailToEdit(emailAddress);
                              setShowNewEmail(true);
                            }}
                          >
                            {translation.domains.editEmail}
                          </Button>
                          <IconButton
                            size={"small"}
                            color="warning"
                            onClick={() => {
                              setEmailToDelete(emailAddress);
                              setOpenConfirmation(true);
                            }}
                          >
                            <DeleteOutline></DeleteOutline>
                          </IconButton>
                        </Stack>
                      </Stack>
                      <Divider />
                    </Stack>
                  ))}
                  {domain.emailAddresses?.length === 0 && (
                    <Typography>{translation.domains.noEmails}</Typography>
                  )}
                </Stack>
              )}
            </CustomTabPanel>
          </Stack>
          <RecordForm
            showNewRecord={showNewRecord}
            setShowNewRecord={setShowNewRecord}
            recordToEdit={recordToEdit}
            setRecordToEdit={setRecordToEdit}
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            domain={domain}
          />
          <EmailForm
            showNewEmail={showNewEmail}
            setShowNewEmail={setShowNewEmail}
            emailToEdit={emailToEdit}
            setEmailToEdit={setEmailToEdit}
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            domain={domain}
          />
          <Dialog
            onClose={() => setOpenConfirmation(false)}
            open={openConfirmation}
            fullWidth
          >
            <DialogTitle>
              {translation.applications.actionConfirmation}
            </DialogTitle>
            <DialogContent>
              {emailToDelete && (
                <Stack spacing={2}>
                  <Typography>
                    {translation.domains.emailToDeleteConfirmation}
                  </Typography>
                  <Typography color={"primary.dark"}>
                    {emailToDelete.fullEmail}
                  </Typography>
                </Stack>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => setOpenConfirmation(false)}
              >
                {translation.closeButton}
              </Button>

              {emailToDelete && (
                <LoadingButton
                  loading={requestLoading}
                  variant="contained"
                  onClick={handleDeleteEmail}
                  color="warning"
                >
                  {translation.deleteButton}
                </LoadingButton>
              )}
            </DialogActions>
          </Dialog>
          <Popover
            id="simple-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 1, color: "text.secondary" }}>
              {translation.copied}
            </Typography>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default DomainPage;
