import axios from "helpers/axios";
import { backendURL } from "config";
import {
  IDomain,
  IDnsRecord,
  INewApplication,
  IApplication,
  IUpdateApplication,
  IEmail,
} from "interfaces";

export async function getDomain(domainId: string) {
  const URL = backendURL + "/domains/" + domainId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function setUpEmailDomain(domainId: string) {
  const URL = backendURL + "/domains/" + domainId + "/setUpEmail";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function getDomains(page: number, itemsPerPage: number) {
  const URL = backendURL + "/domains?pagination=false";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function getAvailableDomains() {
  const URL = backendURL + "/domains?pagination=false&exists[app]=false";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function getApps(page: number, itemsPerPage: number) {
  const URL = backendURL + "/apps?pagination=false";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function getApp(appId: string) {
  const URL = backendURL + "/apps/" + appId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function installCertificateOnApp(appId: string) {
  const URL = backendURL + "/apps/" + appId + "/installCertificate";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function createApp(app: INewApplication) {
  const URL = backendURL + "/apps";

  const res = axios
    .post(
      URL,
      JSON.stringify({
        ...app,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateAppPrimaryDomain(app: IApplication) {
  const URL = backendURL + "/apps/" + app.id + "/updatePrimary";
  const res = axios
    .put(URL, app)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateApp(app: IUpdateApplication) {
  const URL = backendURL + "/apps/" + app.id;
  const res = axios
    .put(URL, app)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function newDnsZone(domain: IDomain) {
  const URL = backendURL + "/domains";
  const res = axios
    .post(URL, domain)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateDnsZone(domain: IDomain) {
  const URL = backendURL + "/domains/" + domain.id;
  const res = axios
    .put(URL, domain)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteDnsZone(domain: IDomain) {
  const URL = backendURL + "/domains/" + domain.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function newActivationCheck(zoneId: string) {
  const URL = backendURL + zoneId + "/new_activation_check";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getZoneStatus(zoneId: string) {
  const URL = backendURL + zoneId + "/status_check";
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function newDnsRecord(dnsRecord: IDnsRecord) {
  const URL = backendURL + "/records";
  const res = axios
    .post(URL, dnsRecord)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateDnsRecord(dnsRecord: IDnsRecord) {
  const URL = backendURL + "/records/" + dnsRecord.id;
  const res = axios
    .put(URL, dnsRecord)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function newEmailDnsRecord(dnsRecord: IDnsRecord) {
  const URL = backendURL + "/records";
  const res = axios
    .post(URL, dnsRecord)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteDnsRecord(dnsRecord: IDnsRecord) {
  const URL = backendURL + "/records/" + dnsRecord.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function newEmail(email: IEmail) {
  const URL = backendURL + "/email_addresses";
  const res = axios
    .post(URL, email)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateEmail(email: IEmail) {
  const URL = backendURL + "/email_addresses/" + email.id;
  const res = axios
    .put(URL, email)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteEmail(email: IEmail) {
  const URL = backendURL + "/email_addresses/" + email.id;
  const res = axios
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}
