import * as React from "react";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

function ExportToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}
export default ExportToolbar;
