import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { routeNames } from "routes";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { IDomain, IUser } from "interfaces";
import { getUsers } from "services/users";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { getDomain, getDomains, newDnsZone } from "services/apps";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { useUserContext } from "components/contexts/UserContext";
import { errorMessage } from "helpers";
import { LoadingButton } from "@mui/lab";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import ExportToolbar from "components/ExportToolbar";

interface Data {
  id: number;
  domain: string;
  status: string;
}

const headCells: HeadCell[] = [
  {
    id: "domain",
    numeric: false,
    disablePadding: false,
    label: "Domain",
    sorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sorting: false,
  },
];

const Domains = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();
  const navigate = useNavigate();
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [newDomain, setNewDomain] = useState("");
  const [domainsCount, setDomainsCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const { loading, setLoading } = useLoadingContext();
  const [newDomainLoading, setNewDomainLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const { userId } = useUserContext();

  const columns: GridColDef[] = [
    { field: "domain", headerName: "Domain", flex: 2 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick: GridEventListener<"rowClick"> = (params) => {
    navigate("/domains/" + params.row.id);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const domainsToData = (domains: IDomain[]) => {
    const data = domains.map((domain: IDomain) => {
      return {
        id: domain.id ? domain.id : 0,
        domain: domain.name,
        status: domain.cloudflareStatus ? domain.cloudflareStatus : "-",
      };
    });

    return data;
  };

  useEffect(() => {
    setLoading(true);

    getDomains(page + 1, rowsPerPage)
      .then((res) => {
        setDomains(res.data["hydra:member"]);
        setRows(domainsToData(res.data["hydra:member"]));
        setDomainsCount(res.data["hydra:totalItems"]);
        setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [page, rowsPerPage]);

  const createDomain = () => {
    if (
      newDomain === "" ||
      !/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
        newDomain
      )
    ) {
      setErrorMessage(translation.domains.invalidDomainNameError);
      return;
    }
    setNewDomainLoading(true);
    newDnsZone({
      name: newDomain,
      owner: "/users/" + userId,
    })
      .then((response) => {
        let domain = response.data;
        setSuccessMessage(translation.savedMessage);
        setNewDomainLoading(false);
        navigate("/domains/" + domain.id);
      })
      .catch((e) => {
        setErrorMessage(errorMessage(e));
        setNewDomainLoading(false);
      });
  };

  return (
    <Box>
      <Stack direction="row" spacing={3}>
        <TextField
          label={translation.domains.newDomainLabel}
          onChange={(event) => {
            setNewDomain(event.target.value);
          }}
          value={newDomain}
        ></TextField>
        <LoadingButton
          loading={newDomainLoading}
          variant="contained"
          onClick={() => {
            createDomain();
          }}
        >
          {translation.domains.addDomain}
        </LoadingButton>
      </Stack>

      {rows.length > 0 ? (
        <>
          <Box sx={{ width: "100%", my: 2 }}>
            <DataGrid
              rows={rows}
              onRowClick={handleClick}
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              columns={columns}
              slots={{ toolbar: ExportToolbar }}
            />
          </Box>
        </>
      ) : (
        !loading && (
          <Box sx={{ mt: 3 }}>
            <Typography>{translation.domains.noDomains}</Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default Domains;
