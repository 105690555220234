import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  MenuItem,
  Stack,
  Paper,
  Button,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, generateRandomString } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import {
  createServer,
  createUser,
  deleteServer,
  getPlansNoPagination,
  updateServer,
  updateUser,
} from "services/users";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import InfoPopover from "components/InfoPopover";
import { IUser, ICompanyInfo, IServer, IPlan } from "interfaces";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import countrylist from "helpers/countryList";
import QuillTextField from "components/Form/QuillTextField/QuillTextField";
import { setNestedObjectValues } from "formik";
import { useEffect, useState } from "react";

const ServerForm = ({ serverToEdit }: { serverToEdit?: IServer }) => {
  const [plans, setPlans] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const navigate = useNavigate();

  const theme = useTheme();

  const translation = useTranslation();
  const { userId } = useUserContext();
  const serverSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    ip: Yup.string().required(translation.required),
    provider: Yup.string().required(translation.required),
    size: Yup.string().required(translation.required),
    cloudwaysId: Yup.string().required(translation.required),
    backupType: Yup.string().required(translation.required),
  });

  useEffect(() => {
    getPlansNoPagination().then((res) => {
      setPlans(res.data["hydra:member"]);
    });
  }, []);

  async function submitForm(
    values: IServer,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any,
    isValid: boolean,
    validateForm: any,
    goBack = false
  ) {
    setSubmitting(true);

    if (isValid) {
      if (!serverToEdit) {
        try {
          await createServer({
            name: values.name,
            ip: values.ip,
            provider: values.provider,
            size: values.size,
            cloudwaysId: values.cloudwaysId,
            backupType: values.backupType,
            plans: values.plans,
          });
          setSuccessMessage(translation.savedMessage);

          setSubmitting(false);
          if (goBack) {
            navigate(routeNames.servers);
          }
          resetForm();
        } catch (error) {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        }
      } else {
        try {
          await updateServer({
            id: serverToEdit.id,
            name: values.name,
            ip: values.ip,
            provider: values.provider,
            size: values.size,
            cloudwaysId: values.cloudwaysId,
            backupType: values.backupType,
            plans: values.plans,
          });
          setSuccessMessage(translation.savedMessage);

          setSubmitting(false);
          if (goBack) {
            navigate(routeNames.servers);
          }
        } catch (error) {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        }
      }
    }
  }

  const handleDeleteServer = () => {
    if (serverToEdit) {
      setDeleteLoading(true);
      deleteServer(serverToEdit)
        .then((response) => {
          setDeleteLoading(false);
          setOpen(false);
          setSuccessMessage(translation.deletedMessage);
          navigate(routeNames.servers);
        })
        .catch((e) => {
          setDeleteLoading(false);
          setOpen(false);
          setErrorMessage(errorMessage(e));
        });
    }
  };

  const initialValues: IServer = {
    name: serverToEdit ? serverToEdit.name : "",
    ip: serverToEdit ? serverToEdit.ip : "",
    provider: serverToEdit ? serverToEdit.provider : "cloudways",
    size: serverToEdit ? serverToEdit.size : "",
    backupType: serverToEdit ? serverToEdit.backupType : "cloudways",
    cloudwaysId: serverToEdit ? serverToEdit.cloudwaysId : "",
    plans: serverToEdit ? serverToEdit.plans : [],
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={serverSchema}
        enableReinitialize
        validateOnMount
        onSubmit={() => {
          console.log("submited");
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validateForm,
          setTouched,
          isValid,

          setSubmitting,
          resetForm,
        }) => {
          return (
            <>
              <form noValidate className="" onSubmit={handleSubmit}>
                <Stack spacing={6}>
                  <Stack direction={"row"} spacing={3}>
                    <Box>
                      <FormTextField
                        label={translation.servers.name}
                        name="name"
                      />
                    </Box>
                    <Box>
                      <FormTextField label={translation.servers.ip} name="ip" />
                    </Box>
                  </Stack>
                  <Stack spacing={3}>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <SelectField
                          label={translation.servers.provider}
                          name="provider"
                        >
                          <MenuItem value="cloudways">Cloudways</MenuItem>
                        </SelectField>
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.servers.size}
                          name="size"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.servers.cloudwaysId}
                          name="cloudwaysId"
                        />
                      </Box>
                      <Box>
                        <SelectField
                          label={translation.servers.backupType}
                          name="backupType"
                        >
                          <MenuItem value="cloudways">Cloudways</MenuItem>
                        </SelectField>
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <SelectField
                          label={translation.servers.plans}
                          multiple={true}
                          name="plans"
                        >
                          {plans.map((plan: IPlan) => {
                            return (
                              <MenuItem value={plan["@id"]} key={plan["@id"]}>
                                {plan.name} - {plan.price} EUR
                              </MenuItem>
                            );
                          })}
                        </SelectField>
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"} spacing={2}>
                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          console.log(validationErrors);
                          if (Object.keys(validationErrors).length > 0) {
                            setTouched(
                              setNestedObjectValues(validationErrors, true)
                            );
                            return;
                          }
                          if (isValid) {
                            submitForm(
                              values,
                              setSubmitting,
                              resetForm,
                              isValid,
                              validateForm
                            );
                          }
                        }}
                      >
                        {translation.saveButton}
                      </LoadingButton>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        loading={isSubmitting}
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (Object.keys(validationErrors).length > 0) {
                            setTouched(
                              setNestedObjectValues(validationErrors, true)
                            );
                            return;
                          }
                          if (isValid) {
                            submitForm(
                              values,
                              setSubmitting,
                              resetForm,
                              isValid,
                              validateForm,
                              true
                            );
                          }
                        }}
                      >
                        {translation.saveAndBackButton}
                      </LoadingButton>
                    </Stack>
                    {serverToEdit && (
                      <Box>
                        <Button
                          variant="outlined"
                          color={"error"}
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          {translation.deleteButton}
                        </Button>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>{" "}
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>{translation.applications.actionConfirmation}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              {translation.servers.serverToDeleteConfirmation}
            </Typography>
            <Typography color={"primary.dark"}>{serverToEdit?.name}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            {translation.closeButton}
          </Button>

          {serverToEdit && (
            <LoadingButton
              loading={deleteLoading}
              variant="contained"
              onClick={handleDeleteServer}
              color="warning"
            >
              {translation.deleteButton}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServerForm;
