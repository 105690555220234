import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { routeNames } from "routes";
import logo from "assets/img/logo.svg";
import userImage from "assets/img/placeholder.png";
import { getUserById, logout } from "services/users";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import {
  Person,
  SettingsInputCompositeOutlined,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import { Divider, useTheme } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { drawerWidth } from "../SideNav";

const Navbar = () => {
  const {
    user,
    isLoading,
    userId,
    updateTrigger,
    companyInfo,
    setCompanyInfo,
    companyIsLoading,
    setCompanyIsLoading,
  } = useUserContext();
  const [userData, setUserData] = useState<any>(null);
  const translation = useTranslation();
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout().then(() => (window.location.href = routeNames.home));
  };

  useEffect(() => {
    if (!isLoading) {
      setCompanyIsLoading(true);
      getUserById(userId)
        .then((user: any) => {
          if (user.data) {
            setUserData(user.data);
            setCompanyInfo(user.data.companyInfo);
            setCompanyIsLoading(false);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            logout().then(() => (window.location.href = routeNames.home));
          }
        });
    }
  }, [isLoading, updateTrigger, userId]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        bgcolor: theme.palette.background.default,
      }}
      elevation={0}
    >
      <Toolbar disableGutters sx={{ marginX: 4 }}>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
          }}
        ></Box>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                variant="rounded"
                src={
                  userData
                    ? userData.photo
                      ? userData.photo.contentUrl
                      : userImage
                    : userImage
                }
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem disabled>
              <Typography variant="body1" gutterBottom>
                {userData
                  ? userData.firstName + " " + userData.lastName
                  : translation.loading}
              </Typography>
            </MenuItem>
            <MenuItem disabled>
              <Typography variant="body2" gutterBottom>
                {userData ? userData.email : translation.loading}
              </Typography>
            </MenuItem>
            <Divider></Divider>
            {/* <MenuItem
              component={Link}
              to={routeNames.myProfile}
              onClick={handleCloseUserMenu}
            >
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              {translation.myProfile}
            </MenuItem>
            <Divider></Divider> */}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {translation.signOut}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
