import * as React from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import ApplicationForm from "./ApplicationForm";

const NewApplication = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();

  return (
    <Box>
      <ApplicationForm />
    </Box>
  );
};

export default NewApplication;
