import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import * as Yup from "yup";
import FormTextField from "components/Form/FormTextField/FormTextField";
import logo from "assets/img/logo-white.svg";
import cover from "assets/img/cover.webp";
import visual from "assets/img/visual.png";
import { useLocation } from "react-router-dom";
import { useUserContext } from "components/contexts/UserContext";
import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { parseJwt } from "helpers";

const SignIn = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [formError, setFormError] = React.useState("");
  const { state }: any = useLocation();
  const { user, loginUser, isLoading, role } = useUserContext();
  const theme = useTheme();
  const notSmall = useMediaQuery(theme.breakpoints.up("md"));

  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .email(translation.signUpEmailError)
      .required(translation.required),
    password: Yup.string()
      .min(6, translation.signUpShortError)
      .required(translation.required),
  });

  React.useEffect(() => {
    if (user !== null) {
      console.log(role);
      if (role === "ROLE_ADMIN") {
        navigate(routeNames.partners);
      } else {
        navigate(routeNames.customers);
      }
    }
  }, [isLoading]);

  return (
    <Stack direction={notSmall ? "row" : undefined} alignItems="stretch">
      <Stack
        sx={{
          display: notSmall ? "flex" : "none",
          backgroundImage: "url(" + cover + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: notSmall ? "100vh" : undefined,
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="row" spacing={2}>
          <img
            src={visual}
            alt="PureIT Visual"
            style={{ width: notSmall ? "24em" : "12em" }}
          />
        </Stack>
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        spacing={4}
        sx={{ bgcolor: "primary.light", width: "100%", height: "100vh" }}
      >
        <img src={logo} alt="src" className="logo-sign-in "></img>
        <Paper sx={{ py: 6, px: 3, bgcolor: "#F8FAFD" }} elevation={3}>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SigninSchema}
            onSubmit={(values, { setSubmitting }) => {
              // same shape as initial values

              loginUser(values.email, values.password)
                .then((response: any) => {
                  const jwt = parseJwt(response.token);
                  console.log(jwt.roles[0]);
                  if (jwt.roles[0] === "ROLE_ADMIN") {
                    navigate(state?.path || routeNames.partners);
                  } else {
                    navigate(state?.path || routeNames.customers);
                  }
                  setSubmitting(false);
                })
                .catch((e: any) => {
                  if (e.response.status === 401) {
                    setFormError(translation.signInInvalidCredentials);
                  }
                  setSubmitting(false);
                });
            }}
          >
            {({ handleSubmit, errors, touched, isValid, isSubmitting }) => (
              <form noValidate className="sign-in-form" onSubmit={handleSubmit}>
                <h5 className="error-label">{formError}</h5>
                <Stack spacing={3}>
                  <FormTextField
                    label={translation.signUpEmailLabel}
                    name="email"
                  />

                  <FormTextField
                    label={translation.signUpPasswordLabel}
                    name="password"
                    type="password"
                  />

                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    size="large"
                  >
                    {translation.signInButton}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default SignIn;
