import axios from "axios";
import axiosAuth from "helpers/axios";
import { backendURL } from "config";
import { parseJwt } from "helpers";
import {
  ICompanyInfo,
  ICustomPlan,
  IPlan,
  IServer,
  ISettings,
  IUser,
} from "interfaces";

export async function getUserById(userId: string) {
  const URL = backendURL + "/users/" + userId;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getUsers(page: number, itemsPerPage: number) {
  const URL =
    backendURL + "/users?page=" + page + "&itemsPerPage=" + itemsPerPage;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getUsersNoPagination() {
  const URL = backendURL + "/users?pagination=false";
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateUser(user: IUser) {
  const URL = backendURL + "/users/" + user.id;
  console.log(user);
  const res = axiosAuth
    .put(
      URL,
      JSON.stringify({
        ...user,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createUser(user: IUser) {
  const URL = backendURL + "/users";

  const res = axiosAuth
    .post(
      URL,
      JSON.stringify({
        ...user,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCustomPlanById(planId: string) {
  const URL = backendURL + "/custom_plans/" + planId;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCustomPlans(page: number, itemsPerPage: number) {
  const URL =
    backendURL + "/custom_plans?page=" + page + "&itemsPerPage=" + itemsPerPage;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCustomPlansNoPagination() {
  const URL = backendURL + "/custom_plans?pagination=false";
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPlanById(planId: string) {
  const URL = backendURL + "/plans/" + planId;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPlans(page: number, itemsPerPage: number) {
  const URL =
    backendURL + "/plans?page=" + page + "&itemsPerPage=" + itemsPerPage;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updatePlan(plan: IPlan) {
  const URL = backendURL + "/plans/" + plan.id;

  const res = axiosAuth
    .put(
      URL,
      JSON.stringify({
        ...plan,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deletePlan(plan: IPlan) {
  const URL = backendURL + "/plans/" + plan.id;

  const res = axiosAuth
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createPlan(plan: IPlan) {
  const URL = backendURL + "/plans";

  const res = axiosAuth
    .post(
      URL,
      JSON.stringify({
        ...plan,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getServerById(serverId: string) {
  const URL = backendURL + "/servers/" + serverId;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getServers(page: number, itemsPerPage: number) {
  const URL = backendURL + "/servers?pagination=false";
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateServer(server: IServer) {
  const URL = backendURL + "/servers/" + server.id;

  const res = axiosAuth
    .put(
      URL,
      JSON.stringify({
        ...server,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteServer(server: IServer) {
  const URL = backendURL + "/servers/" + server.id;

  const res = axiosAuth
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createServer(server: IServer) {
  const URL = backendURL + "/servers";

  const res = axiosAuth
    .post(
      URL,
      JSON.stringify({
        ...server,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getPlansNoPagination() {
  const URL = backendURL + "/plans?pagination=false";
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCustomPlan(plan: ICustomPlan) {
  const URL = backendURL + "/custom_plans/" + plan.id;

  const res = axiosAuth
    .put(
      URL,
      JSON.stringify({
        ...plan,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function deleteCustomPlan(plan: ICustomPlan) {
  const URL = backendURL + "/custom_plans/" + plan.id;

  const res = axiosAuth
    .delete(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCustomPlan(plan: ICustomPlan) {
  const URL = backendURL + "/custom_plans";

  const res = axiosAuth
    .post(
      URL,
      JSON.stringify({
        ...plan,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateCompanyInfo(companyInfo: ICompanyInfo) {
  const URL = backendURL + "/company_infos/" + companyInfo.id;

  const res = axiosAuth
    .put(
      URL,
      JSON.stringify({
        ...companyInfo,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getCompanyInfo(companyInfoId: number) {
  const URL = backendURL + "/company_infos/" + companyInfoId;

  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createCompanyInfo(companyInfo: ICompanyInfo) {
  const URL = backendURL + "/company_infos";

  const res = axiosAuth
    .post(
      URL,
      JSON.stringify({
        ...companyInfo,
      })
    )
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function login(email: string, password: string) {
  return axios
    .post(backendURL + "/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data["token"]);
        const jwt = parseJwt(response.data.token);
        localStorage.setItem("role", jwt.roles[0]);
        localStorage.setItem("id", jwt.id);
      }
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export async function refreshLogin() {
  return axios
    .post(backendURL + "/login/admin/refresh", {
      refresh_token: `${localStorage.getItem("refresh_token")}`,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data["token"]);
        const jwt = parseJwt(response.data.token);
        localStorage.setItem("role", jwt.roles[0]);
        localStorage.setItem("id", jwt.id);
      }
      return response.data;
    })
    .catch(() => {
      localStorage.setItem("user", "error");
      return false;
    });
}

export async function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("id");
  localStorage.removeItem("role");
  localStorage.removeItem("tenantIds");
}

export function authHeader() {
  const jwt = localStorage.getItem("user");
  if (jwt !== null) {
    const user = JSON.parse(jwt);
    if (user && user.token) {
      return { Authorization: "Bearer " + user.token };
    } else {
      return {};
    }
  } else {
    return {};
  }
}

export function getUser() {
  const jwt = localStorage.getItem("user");

  if (jwt !== null) {
    const user = JSON.parse(jwt);

    if (user && user.token) {
      return user;
    } else {
      return {};
    }
  } else {
    return {};
  }
}

export async function createSettings(settings: ISettings) {
  const URL = backendURL + "/settings";
  const res = axiosAuth
    .post(URL, settings)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateSettings(settings: ISettings) {
  const URL = backendURL + "/settings/" + settings.id;
  const res = axiosAuth
    .patch(URL, settings)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function getSettingsByCompanyInfo(companyInfoId: string) {
  const URL = backendURL + "/settings?companyInfo.id=" + companyInfoId;
  const res = axiosAuth
    .get(URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}
