import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import {
  Box,
  Button,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IDomain, IEmail } from "interfaces";
import { errorMessage } from "helpers";
import FormNumberField from "components/Form/FormNumberField";
import { newEmail, updateEmail } from "services/apps";
import { LoadingButton } from "@mui/lab";

const EmailForm = ({
  showNewEmail,
  setShowNewEmail,
  emailToEdit,
  setEmailToEdit,
  updateTrigger,
  setUpdateTrigger,
  setNewEmail,
  domain,
}: {
  showNewEmail: boolean;
  setShowNewEmail: React.Dispatch<React.SetStateAction<boolean>>;
  emailToEdit?: IEmail | null;
  setEmailToEdit?: React.Dispatch<React.SetStateAction<IEmail | null>>;

  updateTrigger: boolean;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNewEmail?: any;
  domain: IDomain;
}) => {
  const { selectedTenant, tenants } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const translation = useTranslation();

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .min(1, translation.signUpShortError)
      .max(64, translation.signUpLongError)
      .required(translation.required),
    password: Yup.string()
      .required(translation.required)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        translation.passwordRequirements
      ),
  });

  return (
    <>
      <Drawer
        anchor="right"
        open={showNewEmail}
        onClose={() => {
          setShowNewEmail(false);
          if (setEmailToEdit) {
            setEmailToEdit(null);
          }
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Formik
          initialValues={{
            email: emailToEdit ? emailToEdit.email : "",
            password: emailToEdit ? emailToEdit.password : "",
          }}
          validationSchema={emailSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (emailToEdit) {
              updateEmail({
                ...emailToEdit,
                password: values.password,
              })
                .then(() => {
                  setSubmitting(false);
                  setShowNewEmail(false);
                  if (setEmailToEdit) {
                    setEmailToEdit(null);
                  }
                  setSuccessMessage(translation.savedMessage);
                  setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setSubmitting(false);
                });
            } else {
              newEmail({
                email: values.email,
                password: values.password,
                domain: domain["@id"] ? domain["@id"] : "",
              })
                .then((res) => {
                  setSubmitting(false);
                  setShowNewEmail(false);
                  if (setEmailToEdit) {
                    setEmailToEdit(null);
                  }
                  setSuccessMessage(translation.savedMessage);
                  setUpdateTrigger(!updateTrigger);
                  if (setNewEmail) {
                    setNewEmail(res.data["@id"]);
                  }
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setSubmitting(false);
                });
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Box sx={{ p: 4 }}>
                <Stack spacing={3}>
                  <Typography>
                    {emailToEdit
                      ? translation.domains.editEmail
                      : translation.domains.addEmail}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FormTextField
                      label={translation.domains.emailLabel}
                      name="email"
                    />
                    <Typography>@{domain.name}</Typography>
                  </Stack>
                  <FormTextField
                    type="password"
                    label={translation.domains.passwordLabel}
                    name="password"
                  />

                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        setShowNewEmail(false);
                        if (setEmailToEdit) {
                          setEmailToEdit(null);
                        }
                      }}
                    >
                      {translation.closeButton}
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {translation.saveButton}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default EmailForm;
