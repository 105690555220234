import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useUserContext } from "components/contexts/UserContext";
const CleanLayout = ({ children }: { children: React.ReactNode }) => {
  const prevInterceptor = useRef<number>(0);
  const { user } = useUserContext();
  // Add wallet address to every request
  useEffect(() => {
    if (prevInterceptor.current) {
      axios.interceptors.request.eject(prevInterceptor.current);
    }

    prevInterceptor.current = axios.interceptors.request.use(function (config) {
      config.headers = {
        "Content-Type": "application/json",
      };
      return config;
    });
  }, [user]);
  return <main>{children}</main>;
};

export default CleanLayout;
