import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik, FormikHelpers } from "formik";
import {
  createSettings,
  getSettingsByCompanyInfo,
  updateSettings,
} from "services/users";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ISettings } from "interfaces";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import currencies from "helpers/currencies";
import FormTextField from "components/Form/FormTextField/FormTextField";
import CompanyForm from "./CompanyForm";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface CurrencyFormValues {
  defaultCurrency?: string;
  enabledCurrencies: string[];
}

interface InvoiceFormValues {
  invoiceSeries?: string;
}

const Settings = () => {
  const { selectedTenant, companyInfo, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [value, setValue] = React.useState(0);
  const [settings, setSettings] = useState<ISettings>({
    taxIncluded: true,
    defaultCurrency: "",
    enabledCurrencies: [],
    invoiceSeries: "",
  });
  const [enabledCurrencies, setEnabledCurrencies] = useState([""]);
  const translation = useTranslation();

  useEffect(() => {
    if (settings.enabledCurrencies && settings.enabledCurrencies.length > 0) {
      setEnabledCurrencies(settings.enabledCurrencies);
    } else {
      setEnabledCurrencies(Object.keys(currencies));
    }
  }, [settings]);

  useEffect(() => {
    setLoading(true);
    if (companyInfo !== null) {
      getSettingsByCompanyInfo(companyInfo.id)
        .then((res) => {
          if (res.data["hydra:member"].length === 0) {
            createSettings({
              taxIncluded: true,
              companyInfo: "company_infos/" + companyInfo.id,
            }).then(() => {
              setUpdateTrigger(!updateTrigger);
            });
          } else {
            setSettings(res.data["hydra:member"][0]);

            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [companyInfo, updateTrigger]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const submitFormCurrency = async (
    values: CurrencyFormValues,
    actions: FormikHelpers<CurrencyFormValues>
  ) => {
    try {
      await updateSettings({
        ...settings,
        defaultCurrency: values.defaultCurrency,
        enabledCurrencies: values.enabledCurrencies,
      });
      actions.setSubmitting(false);
      setUpdateTrigger(!updateTrigger);
      setSuccessMessage(translation.savedMessage);
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      return;
    }
  };

  const submitFormInvoice = async (
    values: InvoiceFormValues,
    actions: FormikHelpers<InvoiceFormValues>
  ) => {
    try {
      await updateSettings({
        ...settings,
        invoiceSeries: values.invoiceSeries,
      });
      actions.setSubmitting(false);
      setUpdateTrigger(!updateTrigger);
      setSuccessMessage(translation.savedMessage);
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      return;
    }
  };

  const initialValuesCurrency: CurrencyFormValues = {
    defaultCurrency: settings.defaultCurrency ? settings.defaultCurrency : "",
    enabledCurrencies: settings.enabledCurrencies
      ? settings.enabledCurrencies
      : [""],
  };

  const initialValuesInvoice: InvoiceFormValues = {
    invoiceSeries: settings.invoiceSeries ? settings.invoiceSeries : "",
  };

  return (
    <Stack spacing={4}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={translation.companyTitle} {...a11yProps(0)} />
          <Tab label={translation.financialSettingsTitle} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {companyInfo && <CompanyForm companyToEdit={companyInfo} />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Stack spacing={4}>
          <Box>
            <Typography variant="h5">{translation.currencyTitle}</Typography>
            <Formik
              initialValues={initialValuesCurrency}
              enableReinitialize
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                submitFormCurrency(values, actions);
              }}
            >
              {({
                isSubmitting,
                submitForm,
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Box sx={{ py: 2, height: "100%" }}>
                  <Stack spacing={2}>
                    <Box>
                      <FormControl variant="standard" sx={{ minWidth: 220 }}>
                        <InputLabel
                          id={"default-currency-select-standard-label"}
                        >
                          {translation.currency.defaultCurrencyLabel}
                        </InputLabel>
                        <Select
                          onChange={(event) => {
                            const currency = event.target.value;
                            if (!values.enabledCurrencies.includes(currency)) {
                              const newEnabledCurrencies = [
                                ...values.enabledCurrencies,
                              ];
                              newEnabledCurrencies.push(currency);
                              setFieldValue(
                                "enabledCurrencies",
                                newEnabledCurrencies
                              );
                              setEnabledCurrencies(newEnabledCurrencies);
                            }
                            setFieldValue("defaultCurrency", currency);
                          }}
                          name="defaultCurrency"
                          labelId={"default-currency-select-standard-label"}
                          id={"default-currency-select-standard"}
                          label={translation.currency.defaultCurrencyLabel}
                          value={values.defaultCurrency}
                          error={
                            touched["defaultCurrency"] &&
                            !!errors.defaultCurrency
                          }
                          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                        >
                          {enabledCurrencies.map((currency) => {
                            return (
                              <MenuItem
                                value={currency}
                                key={"default-" + currency}
                              >
                                {currency}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ maxWidth: 400 }}>
                      <Autocomplete
                        id={"enabledCurrencies"}
                        multiple
                        onChange={(
                          _event: React.SyntheticEvent<Element, Event>,
                          selected: string[]
                        ) => {
                          setFieldValue("enabledCurrencies", selected);
                          if (selected.length > 0) {
                            setEnabledCurrencies(selected);
                          } else {
                            setEnabledCurrencies(Object.keys(currencies));
                          }
                          if (values.defaultCurrency)
                            if (!selected.includes(values.defaultCurrency))
                              setFieldValue("defaultCurrency", "");
                        }}
                        onBlur={() =>
                          setFieldTouched("enabledCurrencies", true)
                        }
                        defaultValue={
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          values.enabledCurrencies as any
                        }
                        value={values.enabledCurrencies}
                        options={Object.keys(currencies)}
                        renderTags={(value: readonly string[], getTagProps) =>
                          value.map((option: string, index: number) => (
                            // eslint-disable-next-line react/jsx-key
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            error={
                              touched.enabledCurrencies &&
                              !!errors.enabledCurrencies
                            }
                            helperText={
                              touched.enabledCurrencies &&
                              !!errors.enabledCurrencies
                                ? errors.enabledCurrencies
                                : ""
                            }
                            variant="standard"
                            {...params}
                            label={translation.currency.enabledCurrenciesLabel}
                          />
                        )}
                      />
                      {/* <AutocompleteTagsField
                    name="enabledCurrencies"
                    label={translation.currency.enabledCurrenciesLabel}
                    children={Object.keys(currencies)}
                  ></AutocompleteTagsField> */}
                    </Box>
                    <Box display={"flex"} justifyContent="flex-start">
                      <LoadingButton
                        type="submit"
                        onClick={submitForm}
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {translation.saveButton}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Box>
              )}
            </Formik>
          </Box>
          <Box>
            <Typography variant="h5">{translation.invoiceTitle}</Typography>
            <Formik
              initialValues={initialValuesInvoice}
              enableReinitialize
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                submitFormInvoice(values, actions);
              }}
            >
              {({ isSubmitting, submitForm }) => (
                <Box sx={{ py: 2, height: "100%" }}>
                  <Stack spacing={2}>
                    <Box>
                      <FormTextField
                        name="invoiceSeries"
                        label={translation.invoice.invoiceSeriesLabel}
                      />
                    </Box>

                    <Box display={"flex"} justifyContent="flex-start">
                      <LoadingButton
                        type="submit"
                        onClick={submitForm}
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {translation.saveButton}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Box>
              )}
            </Formik>
          </Box>
        </Stack>
      </CustomTabPanel>
    </Stack>
  );
};

export default Settings;
