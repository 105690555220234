import SignIn from "pages/Home";
import withPageTitle from "components/PageTitle";
import Dashboard from "pages/Dashboard/Dashboard";
import MyProfile from "pages/MyProfile";
import Customers from "pages/Customers";
import NewCustomer from "pages/Customers/NewCutomer";
import Helpdesk from "pages/Helpdesk";
import Reports from "pages/Reports";
import EditCustomer from "pages/Customers/EditCustomer";
import Domains from "pages/Domains";
import DomainPage from "pages/Domains/DomainPage";
import Plans from "pages/Plans";
import NewPlan from "pages/Plans/NewPlan";
import EditPlan from "pages/Plans/EditPlan";
import Applications from "pages/Applications";
import NewApplication from "pages/Applications/NewApplication";
import ApplicationPage from "pages/Applications/ApplicationPage";
import Partners from "pages/Partners";
import NewPartner from "pages/Partners/NewPartner";
import EditPartner from "pages/Partners/EditPartner";
import AdminPlans from "pages/PurePlans";
import NewAdminPlan from "pages/PurePlans/NewAdminPlan";
import EditAdminPlan from "pages/PurePlans/EditAdminPlan";
import Servers from "pages/Servers";
import EditServer from "pages/Servers/EditServer";
import NewServer from "pages/Servers/NewServer";
import Settings from "pages/Settings";
import AdminApps from "pages/AdminApps";
import AdminDomains from "pages/AdminDomains";

export const routeNames = {
  home: "/",
  signIn: "/",
  dashboard: "/dashboard",
  customers: "/customers",
  editCustomers: "/customers/:customerId",
  newCustomers: "/customers/new",
  domains: "/domains",
  domainPage: "/domains/:domainId",
  plans: "/plans",
  editPlans: "/plans/:planId",
  newPlans: "/plans/new",
  helpdesk: "/helpdesk",
  myProfile: "/my-profile",
  passwordReset: "/password-reset",
  reports: "/reports",
  applications: "/apps",
  newApplications: "/apps/new",
  viewApplication: "/apps/:applicationId",
  adminApps: "/admin/apps",
  adminDomains: "/admin/domains",
  partners: "/admin/partners",
  editPartners: "/admin/partners/:partnerId",
  newPartners: "/admin/partners/new",
  adminPlans: "/billing",
  editAdminPlans: "/admin/plans/:planId",
  newAdminPlans: "/admin/plans/new",
  servers: "/admin/servers",
  editServers: "/admin/servers/:serverId",
  newServers: "/admin/servers/new",
  settings: "/settings",
};

//title is the name of the localisation variable used
const routes: Array<any> = [
  {
    path: routeNames.dashboard,
    titleVariable: "dashboardTitle",
    component: Dashboard,
    authenticatedRoute: true,
  },
  {
    path: routeNames.customers,
    titleVariable: "customersTitle",
    component: Customers,
    authenticatedRoute: true,
  },
  {
    path: routeNames.partners,
    titleVariable: "partnersTitle",
    component: Partners,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newPartners,
    titleVariable: "newPartnersTitle",
    component: NewPartner,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editPartners,
    titleVariable: "editPartnersTitle",
    component: EditPartner,
    authenticatedRoute: true,
  },
  {
    path: routeNames.servers,
    titleVariable: "serversTitle",
    component: Servers,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newServers,
    titleVariable: "newServerTitle",
    component: NewServer,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editServers,
    titleVariable: "editServerTitle",
    component: EditServer,
    authenticatedRoute: true,
  },
  {
    path: routeNames.applications,
    titleVariable: "applicationsTitle",
    component: Applications,
    authenticatedRoute: true,
  },
  {
    path: routeNames.adminApps,
    titleVariable: "applicationsTitle",
    component: AdminApps,
    authenticatedRoute: true,
  },
  {
    path: routeNames.adminDomains,
    titleVariable: "domainsTitle",
    component: AdminDomains,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newApplications,
    titleVariable: "newApplicationTitle",
    component: NewApplication,
    authenticatedRoute: true,
  },
  {
    path: routeNames.viewApplication,
    titleVariable: "viewApplicationTitle",
    component: ApplicationPage,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newCustomers,
    titleVariable: "newCustomerTitle",
    component: NewCustomer,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editCustomers,
    titleVariable: "editCustomerTitle",
    component: EditCustomer,
    authenticatedRoute: true,
  },
  {
    path: routeNames.domains,
    titleVariable: "domainsTitle",
    component: Domains,
    authenticatedRoute: true,
  },
  {
    path: routeNames.domainPage,
    titleVariable: "domainSettingsTitle",
    component: DomainPage,
    authenticatedRoute: true,
  },
  {
    path: routeNames.plans,
    titleVariable: "plansTitle",
    component: Plans,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newPlans,
    titleVariable: "createPlanTitle",
    component: NewPlan,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editPlans,
    titleVariable: "editPlanTitle",
    component: EditPlan,
    authenticatedRoute: true,
  },
  {
    path: routeNames.adminPlans,
    titleVariable: "plansPureTitle",
    component: AdminPlans,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newAdminPlans,
    titleVariable: "createPlanTitle",
    component: NewAdminPlan,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editAdminPlans,
    titleVariable: "editPlanTitle",
    component: EditAdminPlan,
    authenticatedRoute: true,
  },
  {
    path: routeNames.helpdesk,
    titleVariable: "helpdeskTitle",
    component: Helpdesk,
    authenticatedRoute: true,
  },
  {
    path: routeNames.reports,
    titleVariable: "reportsTitle",
    component: Reports,
    authenticatedRoute: true,
  },
  {
    path: routeNames.myProfile,
    titleVariable: "myProfileTitle",
    component: MyProfile,
    authenticatedRoute: true,
  },
  {
    path: routeNames.settings,
    titleVariable: "settingsTitle",
    component: Settings,
    authenticatedRoute: true,
  },
];

//These don't have the layout with navbar & footer
const sRoutes: Array<any> = [
  {
    path: routeNames.home,
    titleVariable: "homeTitle",
    component: SignIn,
  },
];

const mappedRoutes = routes.map((route) => {
  const requiresAuth = Boolean(route.authenticatedRoute);
  const settingRoute = Boolean(route.settingsRoute);
  const ecommerceDesignMenu = Boolean(route.ecommerceDesignMenu);
  const ecommerceCustomOptionsMenu = Boolean(route.ecommerceCustomOptionsMenu);
  const wrappedComponent = withPageTitle(route.titleVariable, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
    titleVariable: route.titleVariable,
    settingsRoute: settingRoute,
    ecommerceDesignMenu: ecommerceDesignMenu,
    ecommerceCustomOptionsMenu: ecommerceCustomOptionsMenu,
  };
});

//These don't have the layout with navbar & footer
export const specialRoutes = sRoutes.map((route) => {
  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(route.titleVariable, route.component);

  return {
    path: route.path,
    titleVariable: route.titleVariable,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});

export default mappedRoutes;
