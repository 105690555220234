let en = {
  homeTitle: "Home",
  homeText: "Register now",
  okButton: "Great",
  confirmDelete: "DELETE",
  signOut: "Sign Out",
  notFound: "Not Found",
  myProfile: "My\u00a0Profile",
  myProfileTitle: "My\u00a0Profile",
  myProfilePage: {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    nickname: "Nickname",
    photo: "Avatar",
  },
  activate: "Activate",
  deactivate: "Deactivate",
  applicationTitle: "Application Settings",
  dnsZone: {
    title: "Dns Zone",
    newDnsZone: "New DNS Zone",
    domainName: "Domain Name",
    noDnsZone: "No DNS zones",
    isManagedLabel: "Managed by DROP",
    status: "Status",
    nameServers: "Nameservers",
    checkNameserversButton: "Check Nameservers",
    setUpManaged: "Set Up Domain",
    setUpUnamaged: "Manage my own domain",
    customDomainsTitle: "Custom domains",
    noCustomDomains: "No custom domains set up",
    addNewDomain: "Add new custom domain",
    invalidDomainNameError: "Enter a valid domain name (root)",
    addButton: "Add",
    rootDomainText: "Use @ for root domain.",
    domainNameTextField: "Domain Name",
    saveDomainNames: "Save",
    mailDomainTitle: "Email domain",
    mailNotSet: "Email domain not set",
    setEmailButton: "Set up email",
    deleteDnsZone: "Delete Zone",
    deletedDnsZone: "Zone deleted",
    newDnsZoneSuccess: "Zone created",
    setUpNameServersFirst: "Please set up and verify nameservers first!",
    deleteZoneMessage: "Are you sure you want to delete the folowing zone?",
    newCloudflareCheckSuccess: "Check successfully initiated.",
  },
  signUpButton: "Sign Up",
  signUpTitle: "Sign Up",
  signUpHeader1: "Start droping your work.",
  signUpHeader2: "Sign up now.",
  signUpFooter: "Keep doing what you do best. The boring stuff is on us.",
  signUpAlready: "You have an account?",
  signUpAlreadyLink: "Sign in here",
  signUpFirstNameLabel: "First Name",
  signUpLastNameLabel: "Last Name",
  signUpEmailLabel: "Email",
  signUpPasswordLabel: "Password",
  signUpButtonLabel: "Start your journey",
  signUpShortError: "Too short, please use more than 2 characters!",
  signUpLongError: "Too Long!",
  signUpEmailError: "Invalid email",
  passwordRequirements:
    "At least 8 characters with a number, upper & lower case required.",
  required: "Required",
  signInHeader: "Do your work. Login to your account.",
  signInMessage: "Forgot password? Reset it.",
  signInButton: "Sign in",
  signInAlready: "You don't have an account?",
  signInAlreadyLink: "Sign up",
  signInInvalidCredentials: "User or password is incorrect!",
  companyName: "Company short name",
  companyLegalName: "Company legal name",
  addressLine1: "Address line 1",
  addressLine2: "Address line 2",
  city: "City",
  state: "State/County",
  zipCode: "Zip Code",
  country: "Country",
  helpdeskTitle: "Support",
  reportsTitle: "Reporting",
  customersTitle: "Customers",
  newCustomerTitle: "New Customer",
  editCustomerTitle: "Edit Customer",
  customers: {
    createCustomerButton: "Create Customer",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    phoneLabel: "PhoneNumber",
    emailLabel: "Email",
    nameLabel: "Company Name",
    legalNameLabel: "Company Legal Name",
    vatIdLabel: "VAT ID",
    regNolabel: "Reg. No.",
    otherInfoLabel: "Other Info",
    countryLabel: "Country",
    countyLabel: "County",
    cityLabel: "City",
    zipCodeLabel: "Zip Code",
    addressLine1Label: "Address Line 1",
    addressLine2Label: "Address Line 2",
    noCustomers: "No Customers",
  },
  partnersTitle: "Partners",
  newPartnersTitle: "New Partners",
  editPartnersTitle: "Edit Partner",
  partners: {
    createPartnerButton: "Create Partner",
    noPartners: "No partners",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    phoneLabel: "PhoneNumber",
    emailLabel: "Email",
    nameLabel: "Company Name",
    legalNameLabel: "Company Legal Name",
    vatIdLabel: "VAT ID",
    regNolabel: "Reg. No.",
    otherInfoLabel: "Other Info",
    countryLabel: "Country",
    countyLabel: "County",
    cityLabel: "City",
    zipCodeLabel: "Zip Code",
    addressLine1Label: "Address Line 1",
    addressLine2Label: "Address Line 2",
  },
  domainsTitle: "Domains",
  domainSettingsTitle: "Domain Settings",
  domains: {
    noDomains: "No domains",
    newDomainLabel: "New Domain Name",
    addDomain: "Add Domain",
    domainName: "Domain Name",
    status: "Status",
    records: "Records",
    email: "Email",
    emailAdresses: "Email Addresses",
    addEmail: "New Email Address",
    editEmail: "Edit Email Address",
    addRecord: "New Record",
    editRecord: "Edit Record",
    emailLabel: "Email",
    passwordLabel: "Password",
    emailToDeleteConfirmation:
      "Are you sure you want to make the following email address?",
    noEmails: "No email addresses.",
    recordNameLabel: "Name",
    recordTypeLabel: "Type",
    recordValueLabel: "Value",
    recordPriorityLabel: "Priority",
    recordTtlLabel: "TTL",
    nameServers: "Nameservers",
    checkNameserversButton: "Check Nameservers",
    invalidDomainNameError: "Enter a valid domain name (root)",
    addButton: "Add",
    rootDomainText: "Use @ for root domain.",
    setEmailButton: "Set up email",
    deleteDnsZone: "Delete Zone",
    deletedDnsZone: "Zone deleted",
    newDnsZoneSuccess: "Zone created",
    setUpNameServersFirst: "Please set up and verify nameservers first!",
    deleteZoneMessage: "Are you sure you want to delete the folowing zone?",
    deleteRecordMessage: "Are you sure you want to delete the folowing record?",
    newCloudflareCheckSuccess: "Check successfully initiated.",
    appManaged: "Managed by app, please change the app settings to modify.",
  },
  plansTitle: "Plans",
  plansPureTitle: "PureIT Pricing",
  billingTitle: "Billing",
  editPlanTitle: "Edit Plan",
  createPlanTitle: "Create Plan",
  plans: {
    noPlans: "No Plans",
    createPlan: "Create Plan",
    name: "Name",
    price: "Price",
    description: "Description",
    specifications: "Specifications",
    qDiscount: "Quarterly discount",
    yDiscount: "Yearly discount",
    customPlansTitle: "Custom Plans",
    purePlansTitle: "Pure IT Pricing",
    planToDeleteConfirmation:
      "Are you sure you want to delete the following plan?",
  },
  settingsTitle: "Settings",
  companyTitle: "Company",
  financialSettingsTitle: "Financial",
  currencyTitle: "Currencies",
  currency: {
    defaultCurrencyLabel: "Default Currency",
    enabledCurrenciesLabel: "Enabled Currencies",
    currencySaved: "Currency settings saved",
  },
  invoiceTitle: "Invoices",
  invoice: {
    invoice: "Invoice",
    invoiceSeriesLabel: "Invoice Series",
    invoiceGenerated: "Invoice Generated",
  },
  applicationsTitle: "Applications",
  newApplicationTitle: "New Application",
  viewApplicationTitle: "View application",
  applications: {
    createAplicationButton: "New Application",
    noApplications: "No applications",
    nameLabel: "Application name",
    customerLabel: "Customer",
    invIntervalLabel: "Invoicing interval",
    planLabel: "PureIT Plan",
    customPlanLabel: "Custom Plan",
    transactionalEmailLabel: "Transactional Email Address",
    domainLabel: "Domain",
    typeLabel: "Application Type",
    endDateLabel: "Contract end date",
    status: "Status",
    createdAt: "Created at",
    url: "Url",
    customer: "Customer",
    domainManagement: "Domain Management",
    applicationDetails: "Application details",
    database: "Database",
    webCredentials: "Application credentials",
    sftp: "Sftp credentials",
    name: "Name",
    user: "User",
    pass: "Pass",
    addDomain: "Attach Domain",
    primaryDomain: "Primary Domain",
    aliases: "Aliases",
    addAlias: "Add Alias",
    domain: "Domain",
    makePrimaryDomain: "Make Primary",
    actionConfirmation: "Confirmation",
    aliasToPrimaryConfirmation:
      "Are you sure you want to make the following alias the primary domain?",
    aliasToDeleteConfirmation:
      "Are you sure you want to delete the following alias?",
    installCertificate: "Install Certificate",
    subdomain: "Subdomain",
    applicationSetings: "Application Settings",
    ip: "Ip",
  },
  serversTitle: "Servers",
  newServerTitle: "New Server",
  editServerTitle: "Edit Server",
  servers: {
    createServer: "Create server",
    noServers: "No servers",
    name: "Name",
    ip: "IP",
    size: "Size",
    provider: "Provider",
    cloudwaysId: "Cloud ID",
    backupType: "Backup Type",
    plans: "Plans",
    serverToDeleteConfirmation:
      "Are you sure you want to delete the following server?",
  },
  dashboardTitle: "Dashboard",
  footerCopyright: "@2022 - TBD Company",
  addNew: "Add new",
  closeButton: "Close",
  cancelButton: "Cancel",
  nextButton: "Next",
  unkownError: "Unkown error. Please contact support!",
  loading: "loading..",
  filters: "Filters",
  status: "Status",
  saveAndCloseButton: "Save & Close",
  saveButton: "Save",
  editButton: "Edit",
  saveAndBackButton: "Save & Go Back",
  saveAndNewButton: "Save & New",
  savedMessage: "Saved",
  deletedMessage: "Deleted",
  deleteButton: "Delete",
  possibleDataLossWarning: "Attention! This could result in data loss!",
  startedOperation: "Started Operation",
  yes: "Yes",
  no: "No",
  copied: "Copied!",
};

export default en;
