import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, MenuItem, Stack, Paper, Button } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, generateRandomString } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import {
  createCompanyInfo,
  createUser,
  updateCompanyInfo,
  updateUser,
} from "services/users";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import InfoPopover from "components/InfoPopover";
import { IUser, ICompanyInfo } from "interfaces";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import countrylist from "helpers/countryList";
import { setNestedObjectValues } from "formik";

const CompanyForm = ({ companyToEdit }: { companyToEdit?: ICompanyInfo }) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const { updateTrigger, setUpdateTrigger } = useUserContext();
  const navigate = useNavigate();

  const translation = useTranslation();
  const { userId } = useUserContext();
  const customerSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    legalName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    vatId: Yup.string()
      .min(2, translation.signUpShortError)
      .max(31, translation.signUpLongError)
      .required(translation.required),
    regNo: Yup.string()
      .min(2, translation.signUpShortError)
      .max(31, translation.signUpLongError)
      .required(translation.required),
    otherInfo: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError),
    country: Yup.string().required(translation.required),
    county: Yup.string()
      .min(2, translation.signUpShortError)
      .max(63, translation.signUpLongError)
      .required(translation.required),
    city: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    zipCode: Yup.string()
      .min(2, translation.signUpShortError)
      .max(15, translation.signUpLongError),
    addressLine1: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    addressLine2: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError),
  });

  async function submitForm(
    values: ICompanyInfo,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any,
    goBack = false
  ) {
    setSubmitting(true);
    if (!companyToEdit) {
      try {
        await createCompanyInfo({
          name: values.name,
          legalName: values.legalName,
          vatId: values.vatId,
          regNo: values.regNo,
          otherInfo: values.otherInfo,
          country: values.country,
          county: values.county,
          city: values.city,
          zipCode: values.zipCode,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
        });
        setSuccessMessage(translation.savedMessage);

        setSubmitting(false);
        if (goBack) {
          navigate(routeNames.customers);
        }
        resetForm();
      } catch (error) {
        setErrorMessage(errorMessage(error));
        setSubmitting(false);
      }
    } else {
      try {
        await updateCompanyInfo({
          name: values.name,
          legalName: values.legalName,
          vatId: values.vatId,
          regNo: values.regNo,
          otherInfo: values.otherInfo,
          country: values.country,
          county: values.county,
          city: values.city,
          zipCode: values.zipCode,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
        });
        setSuccessMessage(translation.savedMessage);
        setUpdateTrigger(!updateTrigger);
        setSubmitting(false);
      } catch (error) {
        setErrorMessage(errorMessage(error));
        setSubmitting(false);
      }
    }
  }

  const initialValues: ICompanyInfo = {
    name: companyToEdit ? companyToEdit.name : "",
    legalName: companyToEdit ? companyToEdit.legalName : "",
    vatId: companyToEdit ? companyToEdit.vatId : "",
    regNo: companyToEdit ? companyToEdit.regNo : "",
    otherInfo: companyToEdit ? companyToEdit.otherInfo : "",
    country: companyToEdit ? companyToEdit.country : "",
    county: companyToEdit ? companyToEdit.county : "",
    city: companyToEdit ? companyToEdit.city : "",
    zipCode: companyToEdit ? companyToEdit.zipCode : "",
    addressLine1: companyToEdit ? companyToEdit.addressLine1 : "",
    addressLine2: companyToEdit ? companyToEdit.addressLine2 : "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={customerSchema}
        enableReinitialize
        onSubmit={() => {
          console.log("submited");
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setSubmitting,
          validateForm,
          setTouched,
          isValid,

          resetForm,
        }) => {
          return (
            <>
              <form noValidate className="" onSubmit={handleSubmit}>
                <Stack spacing={6}>
                  <Stack spacing={3}>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <FormTextField
                          label={translation.customers.nameLabel}
                          name="name"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.legalNameLabel}
                          name="legalName"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.vatIdLabel}
                          name="vatId"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <FormTextField
                          label={translation.customers.regNolabel}
                          name="regNo"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.otherInfoLabel}
                          name="otherInfo"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box sx={{ width: "259px" }}>
                        <AutocompleteCountryField
                          controlId="country1"
                          label={translation.customers.countryLabel}
                          name="country"
                          type="text"
                          options={countrylist}
                          placeholder={false}
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.countyLabel}
                          name="county"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.cityLabel}
                          name="city"
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={3}>
                      <Box>
                        <FormTextField
                          label={translation.customers.addressLine1Label}
                          name="addressLine1"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.addressLine2Label}
                          name="addressLine2"
                        />
                      </Box>
                      <Box>
                        <FormTextField
                          label={translation.customers.zipCodeLabel}
                          name="zipCode"
                        />
                      </Box>
                    </Stack>
                  </Stack>

                  <Stack direction={"row"} spacing={2}>
                    <LoadingButton
                      variant="contained"
                      loading={isSubmitting}
                      onClick={async () => {
                        const validationErrors = await validateForm();
                        if (Object.keys(validationErrors).length > 0) {
                          setTouched(
                            setNestedObjectValues(validationErrors, true)
                          );
                          return;
                        }
                        if (isValid) {
                          submitForm(values, setSubmitting, resetForm);
                        }
                      }}
                    >
                      {translation.saveButton}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>{" "}
    </>
  );
};

export default CompanyForm;
