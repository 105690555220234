import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import { ICustomPlan, IPlan, IUser } from "interfaces";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { getCustomPlanById, getPlanById, getUserById } from "services/users";
import AdminPlanForm from "./AdminPlanForm";

const EditAdminPlan = () => {
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState<IPlan | null>(null);
  const { planId } = useParams();

  useEffect(() => {
    if (planId !== undefined) {
      getPlanById(planId)
        .then((response) => {
          if (response.data) {
            setPlan(response.data);
            setIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [planId]);

  return (
    <Box>
      {isLoading || plan == null ? (
        <LoadingIcon />
      ) : (
        <>
          <AdminPlanForm planToEdit={plan}></AdminPlanForm>
        </>
      )}
    </Box>
  );
};

export default EditAdminPlan;
