import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  HomeOutlined,
  EventAvailableOutlined,
  ShoppingBasketOutlined,
  GroupOutlined,
  LightbulbOutlined,
  SellOutlined,
  LoyaltyOutlined,
  BarChartOutlined,
  DriveFileMoveOutlined,
  ChatBubbleOutlineOutlined,
  PieChartOutlineOutlined,
  PieChartOutlined,
  FaceOutlined,
  WebhookOutlined,
  GradeOutlined,
  HolidayVillageOutlined,
  DnsOutlined,
  Assignment,
  AssignmentOutlined,
} from "@mui/icons-material";
import { routeNames } from "routes";
import { useUserContext } from "components/contexts/UserContext";
import logo from "assets/img/logo.svg";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import useTranslation from "components/customHooks/translations";
import { Link } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";

export const drawerWidth = 240;

export default function SideNav() {
  const { user, isLoading, userId, role } = useUserContext();
  const translation = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor: theme.palette.background.default,
          border: 0,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ pb: "100px", display: "flex", justifyContent: "center" }}>
        <Link to={user ? routeNames.home : routeNames.home}>
          <img src={logo}></img>
        </Link>
      </Toolbar>
      {role === "ROLE_ADMIN" ? (
        <List sx={{ p: 1 }}>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.partners)}
              component={Link}
              to={routeNames.partners}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <FaceOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.partnersTitle}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.servers)}
              component={Link}
              to={routeNames.servers}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <DnsOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.serversTitle}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.adminDomains)}
              component={Link}
              to={routeNames.adminDomains}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <WebhookOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.domainsTitle}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.adminApps)}
              component={Link}
              to={routeNames.adminApps}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <HolidayVillageOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.applicationsTitle}
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.domains)}
              component={Link}
              to={routeNames.domains}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <WebhookOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.domainsTitle}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.applications)}
              component={Link}
              to={routeNames.applications}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <HolidayVillageOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.applicationsTitle}
              />
            </ListItemButton>
          </ListItem> */}

          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.adminPlans)}
              component={Link}
              to={routeNames.adminPlans}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <GradeOutlined color="primary" />
              </ListItemIcon>
              <ListItemText color="primary" primary={translation.plansTitle} />
            </ListItemButton>
          </ListItem>
          {/* <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.helpdesk)}
              component={Link}
              disabled
              to={routeNames.helpdesk}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <ChatBubbleOutlineOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.helpdeskTitle}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              selected={location.pathname.includes(routeNames.reports)}
              component={Link}
              disabled
              to={routeNames.reports}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <PieChartOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.reportsTitle}
              />
            </ListItemButton>
          </ListItem> */}
        </List>
      ) : (
        <List sx={{ p: 1 }}>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.customers)}
              component={Link}
              to={routeNames.customers}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <FaceOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.customersTitle}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.domains)}
              component={Link}
              to={routeNames.domains}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <WebhookOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.domainsTitle}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.applications)}
              component={Link}
              to={routeNames.applications}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <HolidayVillageOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.applicationsTitle}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.plans)}
              component={Link}
              to={routeNames.plans}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <GradeOutlined color="primary" />
              </ListItemIcon>
              <ListItemText color="primary" primary={translation.plansTitle} />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.adminPlans)}
              component={Link}
              to={routeNames.adminPlans}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <AssignmentOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.billingTitle}
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 1 }}>
            <ListItemButton
              selected={location.pathname.includes(routeNames.helpdesk)}
              component={Link}
              disabled
              to={routeNames.helpdesk}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <ChatBubbleOutlineOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.helpdeskTitle}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              selected={location.pathname.includes(routeNames.reports)}
              component={Link}
              disabled
              to={routeNames.reports}
              sx={{ borderRadius: 1 }}
            >
              <ListItemIcon>
                <PieChartOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                color="primary"
                primary={translation.reportsTitle}
              />
            </ListItemButton>
          </ListItem> */}
        </List>
      )}
    </Drawer>
  );
}
